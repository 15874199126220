import { Component, Input, OnInit } from '@angular/core';
import { AppConst } from '@app/App.const';
import { NavigationService } from '@app/shared/common/navigation/navigation.service';
import { ApiEndpointsService } from '@app/shared/common/services/api-endpoint.service';
import { ApiHttpService } from '@app/shared/common/services/api-http.service';
import { ToasterService } from '@app/shared/common/services/toaster.service';

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.scss'],
})
export class ManageNotificationComponent implements OnInit {
  public manageNotificationSettings: ManageNotificationsJSON[] = [];
  public modifiedFlag: boolean = false;
  public searchText: string = '';
  @Input() isNotificationScreen: boolean = true;

  constructor(
    public Api: ApiHttpService,
    private ApiEndPoint: ApiEndpointsService,
    private toasterService: ToasterService,
    private navigation: NavigationService
  ) {}

  ngOnInit(): void {
    this.getManageSettings();
  }

  public getManageSettings() {
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.ApiEndPoint.getManageSettings(),
      null
    ).subscribe((res: ManageNotificationsJSON[]) => {
      if (res.length) {
        this.modifiedFlag = false;
        this.manageNotificationSettings = res;
      }
    });
  }

  public saveManageNotifications() {
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.ApiEndPoint.getSaveManageSettings(),
      null
    ).subscribe((res: any) => {
      if (res.success) {
        this.toasterService.showToasterSuccess(res.message);
      } else {
        this.toasterService.showToasterError(res.error.message);
      }
    });
  }

  public navigateBack() {
    this.navigation.navigateTo(this.navigation.getAllNotification());
  }
}

interface ManageNotificationsJSON {
  uuid: string;
  title: string;
  description: string;
  isEnabled: boolean;
  isMandatory: boolean;
}
