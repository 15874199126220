<motif-modal-title class="display-flex">
  <h3>Session Inactivity</h3>
  <button
    motifIconButton
    aria-label="Close modal"
    (click)="logout()"
  >
    <motif-icon
      [src]="'/assets/icons/navigation/ic_close_24px.svg'"
    ></motif-icon>
  </button>
</motif-modal-title>
<motif-modal-content>
  <div class="timer">
    <motif-icon [src]="'/assets/icons/image/ic_timer_24px.svg'"></motif-icon>
    <countdown
      [config]="{ leftTime: 120, format: 'mm:ss' }"
      (event)="onTimerFinished($event)"
    ></countdown>
  </div>
  <div class="description-text">
    For your security, this session will time out due to inactivity. If you want
    to continue working, please select ‘Extend session’ button.
  </div>
</motif-modal-content>
<motif-modal-actions>
  <button
    motifButton
    class="approve-yes"
    color="primary-alt"
    (click)="sessionExtent()"
  >
    Extend session
  </button>

  <button motifButton class="approve-no" color="secondary" (click)="logout()">
    Log out
  </button>
</motif-modal-actions>
