import { Component, OnInit } from '@angular/core';
import { CountdownEvent } from '@app/shared/common/utils/app-interface';
import { MotifModal } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-session-extend-modal',
  templateUrl: './session-extend-modal.component.html',
  styleUrls: ['./session-extend-modal.component.scss'],
})
export class SessionExtendModalComponent implements OnInit {
  public modalRef: any;
  constructor(private modal: MotifModal) {}

  ngOnInit(): void {
    this.modalRef = this.modal.getModalById('session-extend-modal');
  }

  onTimerFinished(e: CountdownEvent) {
    if (e.action == 'done') {
      this.modalRef.close({ button: 'Time out' });
    }
  }

  close(): void {
    this.logout();
  }
  sessionExtent() {
    this.modalRef.close({ button: 'Extend session' });
  }

  logout() {
    this.modalRef.close({ button: 'Log out' });
  }
}
