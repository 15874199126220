import {
  VerticalNavigationCategoryGroup,
  VerticalNavigationRouterConfig,
} from '@ey-xd/ng-motif';

export class MenuItem implements VerticalNavigationCategoryGroup {
  text: string;
  icon: string;
  type: 'internal' | 'external';
  route: VerticalNavigationRouterConfig;
  children: SubMenuItem[] | undefined;
  constructor(
    text: string,
    icon: string,
    type: 'internal' | 'external',
    route: string | any[],
    children?: SubMenuItem[]
  ) {
    this.text = text;
    this.icon = icon;
    this.type = type;
    this.route = { routerLink: route };
    this.children = children;
  }
}

export class SubMenuItem extends MenuItem {
  parent: string;

  constructor(
    parent: string,
    text: string,
    type: 'internal' | 'external',
    route: string | any[],
    url?: string
  ) {
    super('', '', 'internal', '');
    this.parent = parent;
    this.text = text;
    this.type = type;
    this.route = { routerLink: route };
  }
}
