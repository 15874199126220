import { AuthConfig } from 'angular-oauth2-oidc';

/**
 * We can get the information needed for AuthConfig from
 * https://login.microsoftonline.com/<YOURTENANT>.onmicrosoft.com/.well-known/openid-configuration
 * and https://login.microsoftonline.com/<YOURTENANT>.onmicrosoft.com/discovery/keys
 */
export const oAuthConfig = {
  issuer: 'https://sts.windows.net/5b973f99-77df-4beb-b27d-aa0c70b8482c/',
  scope: 'openid profile email',
  responseType: 'token id_token',
  resource: 'https://graph.microsoft.com',
  postLogoutRedirectUri: '',
  strictDiscoveryDocumentValidation: false,
  clearHashAfterLogin: false,
  oidc: true,
  jwks: {
    keys: [
      {
        kid: 'nbCwW11w3XkB-xUaXwKRSLjMHGQ',
        use: 'sig',
        kty: 'RSA',
        e: 'AQAB',
        n: 'u98KvoUHfs2z2YJyfkJzaGFYM58eD0epHfETTwNDl6AL_cTfOklcxM4jrLWvVqqp2sHaH0gFpYPyovN-_akmE_4fkc0Vw_wGM5jDP-jnOJ1vBvbFoF7uBAy4r3ln2ey1PoGUhpkXdDawhIfdAbc7WLtyopHNWQXI336rXiwjvcjL8dHhievDOktsAsilADP5wJT0lyTifONPZOq-XWCw9FtXAQr7DniOC5uDuUaL0mM1UJChiCrDmFOAf6CNdu2SwLinXYauqM9ORElKYEChoEfi51fcsmlsn4mtNPkxstvR7OJiJBpvk7FLeiaBtMnsO5x30DPgrhAagrVn3IaKRQ',
      },
    ],
  },
};

export const authConfig: AuthConfig = {
  issuer: oAuthConfig.issuer,
  // URL of the SPA to redirect the user to after login
  redirectUri: ' ',
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: '',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC.
  scope: oAuthConfig.scope,
  logoutUrl: '',
  // The auth server's endpoint that allows to log
  // the user in when using implicit flow.
  loginUrl: '',
  // BE resource url
  resource: '',
  responseType: oAuthConfig.responseType,
  clearHashAfterLogin: oAuthConfig.clearHashAfterLogin,
  // URL to redirect to after logout
  postLogoutRedirectUri: oAuthConfig.postLogoutRedirectUri,
  strictDiscoveryDocumentValidation:
    oAuthConfig.strictDiscoveryDocumentValidation,
  oidc: oAuthConfig.oidc,
  jwks: {
    keys: [
      {
        kid: oAuthConfig.jwks.keys[0].kid,
        use: oAuthConfig.jwks.keys[0].use,
        kty: oAuthConfig.jwks.keys[0].kty,
        e: oAuthConfig.jwks.keys[0].e,
        n: oAuthConfig.jwks.keys[0].n,
      },
    ],
  },
  silentRefreshRedirectUri: '',
  siletRefreshTimeout: 0,
  timeoutFactor: 0.25,
};
