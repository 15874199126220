import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService implements OnDestroy {
  public isTableOperationsEventCalled = new Subject<{
    event: string;
    data: any;
  }>();
  @Output() filterChangedEvent=new EventEmitter<any>();
  @Output() filterChanged=new EventEmitter<any>();


  public showTableLoader() {
    this.isTableOperationsEventCalled.next({ event: 'showLoader', data: null });
  }

  public HideTableLoader() {
    this.isTableOperationsEventCalled.next({ event: 'hideLoader', data: null });
  }

  public changeFilter(event: any) {
    this.filterChangedEvent.emit(event);
  }

  public onFilterChange(event: any){
    
    this.filterChanged.emit(event);
  }

  public searchValueSubject = new Subject<string>();
  
  ngOnDestroy() {
    this.isTableOperationsEventCalled.complete();
  }
}
