import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: any, searchText: string): any {
    if (!value) return null;
    if (!searchText) return value;
    const searchTextLowerCase = searchText.toLowerCase();
    return value.filter((each: any) => {
      return JSON.stringify(each).toLowerCase().includes(searchTextLowerCase);
    });
  }
}
