import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConst } from '@app/App.const';
import { NavigationService } from '@app/shared/common/navigation/navigation.service';
import { ApiHttpService } from '@app/shared/common/services/api-http.service';
import { SignalRConnectionService } from '@app/shared/common/services/signal-r-connection.service';
import { ToasterService } from '@app/shared/common/services/toaster.service';
import { dateComparator } from '@app/shared/common/utils/ag-grid';
import { groupTypes } from '@app/shared/common/utils/app-enum';
import { notificationJSON } from '@app/shared/common/utils/app-interface';
import {
  dateTimeFormatter,
  removeSpace,
  returnColumnDef,
} from '@app/shared/common/utils/app-utils';
import { ColDef, RowClassRules } from 'ag-grid-community';

enum filterOptionsEnum {
  flagged,
  unseen,
  uncompletedTask,
}

@Component({
  selector: 'app-notification-all',
  templateUrl: './notification-all.component.html',
  styleUrls: ['./notification-all.component.scss'],
})
export class NotificationAllComponent implements OnInit {
  public filterParams = {
    comparator: dateComparator,
    browserDatePicker: true,
    inRangeInclusive: true,
  };

  public defaultColDef: ColDef = {
    width: 200,
    resizable: true,
    autoHeight: true,
    sortable: true,
    floatingFilter: true,
  };

  public notifications: notificationJSON[] = [];
  public notificationsBackup: notificationJSON[] = [];
  public categorize: boolean = false;
  public columnDef: any;
  public selectedRecords: any;
  public selectedRecordCount: number = 0;
  public defaultOpenGroup!: number;
  public isErrorRequired: boolean = true;

  @ViewChild('descriptionData')
  descriptionData!: TemplateRef<any>;

  @ViewChild('clienNameWithAvatar')
  clienNameWithAvatar!: TemplateRef<any>;

  @ViewChild('actionOperations')
  actionOperations!: TemplateRef<any>;

  public filterOptions: { id: number; name: string; isSelected: boolean }[] = [
    { id: filterOptionsEnum.flagged, name: 'Flagged', isSelected: false },
    { id: filterOptionsEnum.unseen, name: 'Unseen', isSelected: false },
    {
      id: filterOptionsEnum.uncompletedTask,
      name: 'Uncompleted task',
      isSelected: false,
    },
  ];

  public actionOnBulkRecords: {
    seen: boolean | null;
    completed: boolean | null;
    flag: boolean | null;
    delete: boolean;
  } = {
    seen: true,
    completed: true,
    flag: true,
    delete: true,
  };

  public groupOnType: {
    id: number;
    title: string;
    updatedBefore: any;
    notificationsByGroupType: notificationJSON[];
  }[] = [
    {
      id: groupTypes.fundRequest,
      title: 'Fund Request',
      updatedBefore: '',
      notificationsByGroupType: [],
    },
    {
      id: groupTypes.dataIngestion,
      title: 'Data Ingestion',
      updatedBefore: '',
      notificationsByGroupType: [],
    },
    {
      id: groupTypes.workpaper,
      title: 'Workpaper',
      updatedBefore: '',
      notificationsByGroupType: [],
    },
    {
      id: groupTypes.error,
      title: 'Error',
      updatedBefore: '',
      notificationsByGroupType: [],
    },
  ];

  categorizeAsUnseen(notifications: notificationJSON[]) {
    this.categorize = true;
    this.getAllNotifications(notifications);
    if (this.defaultOpenGroup !== -1) {
      // below added to see only unseen notifications which are coming from notification container --starts
      this.filterOptions.forEach(
        (option: { id: number; name: string; isSelected: boolean }) => {
          if (option.id === filterOptionsEnum.unseen) {
            option.isSelected = false;
            this.fastFiltersApplied(option);
          }
        }
      );
      // below added to see only unseen notifications which are coming from notification container --ends
    }
  }

  constructor(
    public Api: ApiHttpService,
    private toasterService: ToasterService,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private SignalRConnectionService: SignalRConnectionService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((param: any) => {
      if (!!param.groupType) {
        this.defaultOpenGroup = param.groupType;
        this.isErrorRequired = false;
        this.categorizeAsUnseen(
          this.SignalRConnectionService.notifications.value
        );
      } else {
        this.defaultOpenGroup = -1;
        this.isErrorRequired = true;
        this.resetfilterOptions();
        this.SignalRConnectionService.refreshNotifications();
      }
    });
    this.SignalRConnectionService.notifications.subscribe(
      (notifications: notificationJSON[]) => {
        this.categorizeAsUnseen(notifications);
      }
    );
  }

  public getAllNotifications(notifications: notificationJSON[]) {
    this.resetGroupType();
    this.resetfilterOptions();
    //sorting and adding all completed at last -- starts
    const res = [...notifications].sort(
      (a: any, b: any) => a.completed - b.completed
    );
    //sorting and adding all completed at last -- ends
    this.columnDef = this.prepareColumnDefs(res[0]);
    this.columnDef.splice(
      0,
      0,
      {
        field: ' ',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerTooltip: 'checkbox',
        width: 70,
      },
      {
        ...returnColumnDef('action', 1, false, this.actionOperations),
        pinned: 'right',
      }
    );
    this.notificationsBackup = JSON.parse(JSON.stringify(res));
    this.notifications = res;
    if (this.categorize) {
      this.categorizeChecked();
    }
  }

  public prepareColumnDefs(data: any) {
    return Object.keys(data)
      ?.filter(
        (key) =>
          key != 'groupType' &&
          key != 'error' &&
          key != 'fundRequestId' &&
          key != 'fundName' &&
          key != 'isExpanded' &&
          key != 'notificationActionId' &&
          key != 'seen' &&
          key != 'completed' &&
          key != 'flag'
      )
      .map((val: any, index) => {
        let obj: any;
        if (val === 'clientName') {
          obj = returnColumnDef(val, 1, false, this.clienNameWithAvatar);
        } else if (val === 'description') {
          obj = returnColumnDef(val, index, false, this.descriptionData);
        } else {
          obj = returnColumnDef(val, index);
        }
        obj['flex'] = val !== 'description' ? 1 : 3;
        // below code is added to format dates
        if (obj.field.toUpperCase().indexOf('DATE') !== -1) {
          obj['filter'] = 'agDateColumnFilter';
          obj['filterParams'] = this.filterParams;
          obj['valueFormatter'] = dateTimeFormatter;
        }
        return obj;
      });
  }

  public fastFiltersApplied(filter: {
    id: number;
    name: string;
    isSelected: boolean;
  }) {
    this.selectedRecords = {};
    this.selectedRecordCount = 0;
    this.notifications = JSON.parse(JSON.stringify(this.notificationsBackup));
    this.resetGroupType();
    filter.isSelected = filter.isSelected
      ? false
      : (this.resetfilterOptions(), this.applyFilter(filter.id), true);
    if (this.categorize) {
      this.categorizeChecked();
    }
  }

  public applyFilter(filterId: number) {
    switch (filterId) {
      case filterOptionsEnum.flagged:
        this.notifications = this.notifications.filter(
          (notification: notificationJSON) => notification.flag === true
        );
        break;
      case filterOptionsEnum.unseen:
        this.notifications = this.notifications.filter(
          (notification: notificationJSON) => notification.seen === false
        );
        break;
      case filterOptionsEnum.uncompletedTask:
        this.notifications = this.notifications.filter(
          (notification: notificationJSON) => notification.completed === false
        );
        break;
      default:
        this.notifications = JSON.parse(
          JSON.stringify(this.notificationsBackup)
        );
    }
  }

  public resetfilterOptions() {
    this.filterOptions.forEach(
      (filter: { name: string; isSelected: boolean }) => {
        filter.isSelected = false;
      }
    );
  }

  public resetGroupType() {
    this.groupOnType.forEach((group: { updatedBefore: any }) => {
      group.updatedBefore = '';
    });
  }

  public onCellClicked(event: any) {
    if (
      event.column.colId === ' ' ||
      event.column.colId === 'action' ||
      removeSpace(event.data.groupType)?.toUpperCase() ===
        groupTypes[groupTypes.dataIngestion].toUpperCase() ||
      event.data.error
    ) {
      // return true;
    } else {
      this.navigation.navigateWithQueryParam(
        this.navigation.getAuditorFundDetails(
          event.data.fundRequestId,
          event.data.fundName,
          event.data.administrator,
          event.data.aggConsoleFlag
        ),
        { isParent: event.data.isParent }
      );
    }
  }

  public performAction(
    action: 'delete' | 'flag' | 'completed' | 'seen',
    messageUuid: string,
    bulkAction: boolean = false
  ) {
    if (!(!bulkAction && action === 'seen')) {
      let url = this.urlForActions(action);
      this.Api.callApi(AppConst.HttpMethods.GET, url, null).subscribe(
        (res: any) => {
          if (!res?.error) {
            this.toasterService.showToasterSuccess(res.message);
            if (action === 'completed') {
              this.notifications.sort(
                (a: any, b: any) => a.completed - b.completed
              );
            }
          } else {
            this.toasterService.showToasterError(
              res?.error.message
                ? res?.error.message
                : `Failed to perform ${action} action!`
            );
          }
        }
      );
    }
  }

  public urlForActions(
    action: 'delete' | 'flag' | 'completed' | 'seen'
  ): string {
    let url = '1';
    if (action === 'delete') {
      url = '2'; //delete url
    } else if (action === 'completed') {
      url = '3'; //completed url
    } else if (action === 'flag') {
      url = '5'; //flag url
    } else if (action === 'seen') {
      url = '6'; //flag url
    }
    return url;
  }

  public categorizeChecked() {
    this.selectedRecordCount = 0;
    this.selectedRecords = {};
    if (this.categorize) {
      const updatedBefore = function (
        group: any,
        notification: notificationJSON
      ) {
        group.updatedBefore =
          group.updatedBefore === ''
            ? notification.dateTime
            : new Date(group.updatedBefore).getTime() <
              new Date(notification.dateTime).getTime()
            ? notification.dateTime
            : group.updatedBefore;
        return true;
      };

      this.groupOnType.forEach(
        (group: {
          id: number;
          title: string;
          updatedBefore: any;
          notificationsByGroupType: notificationJSON[];
        }) => {
          switch (group.id) {
            case groupTypes.fundRequest:
              group.notificationsByGroupType = this.notifications.filter(
                (notification: notificationJSON) =>
                  (!this.isErrorRequired || !notification.error) &&
                  removeSpace(notification.groupType)?.toUpperCase() ===
                    groupTypes[groupTypes.fundRequest]?.toUpperCase() &&
                  updatedBefore(group, notification)
              );
              group.updatedBefore = this.getUpdatedBeforeTime(
                group.updatedBefore
              );
              break;
            case groupTypes.workpaper:
              group.notificationsByGroupType = this.notifications.filter(
                (notification: notificationJSON) =>
                  (!this.isErrorRequired || !notification.error) &&
                  removeSpace(notification.groupType)?.toUpperCase() ===
                    groupTypes[groupTypes.workpaper].toUpperCase() &&
                  updatedBefore(group, notification)
              );
              group.updatedBefore = this.getUpdatedBeforeTime(
                group.updatedBefore
              );
              break;
            case groupTypes.dataIngestion:
              group.notificationsByGroupType = this.notifications.filter(
                (notification: notificationJSON) =>
                  (!this.isErrorRequired || !notification.error) &&
                  removeSpace(notification.groupType)?.toUpperCase() ===
                    groupTypes[groupTypes.dataIngestion].toUpperCase() &&
                  updatedBefore(group, notification)
              );
              group.updatedBefore = this.getUpdatedBeforeTime(
                group.updatedBefore
              );
              break;
            case groupTypes.error:
              group.notificationsByGroupType = this.notifications.filter(
                (notification: notificationJSON) =>
                  notification.error && updatedBefore(group, notification)
              );
              group.updatedBefore = this.getUpdatedBeforeTime(
                group.updatedBefore
              );
              break;
          }
        }
      );
    }
  }

  public getUpdatedBeforeTime(dateTimeLatest: any) {
    // returns seconds
    return this.convertMS(
      Math.abs(new Date().getTime() - new Date(dateTimeLatest).getTime())
    );
  }

  public convertMS(milliseconds: any) {
    let week,
      day,
      hour,
      minute,
      seconds = 0;
    let returnValue: string = '';
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    if (seconds) {
      returnValue = `${seconds} seconds`;
    }
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    if (minute) {
      returnValue = `${minute} minutes`;
    }
    day = Math.floor(hour / 24);
    hour = hour % 24;
    if (hour) {
      returnValue = `${hour} hours`;
    }
    if (day) {
      returnValue = `${day} days`;
    }
    week = Math.floor(day / 7);
    if (week) {
      returnValue = `${week} weeks`;
    }
    return returnValue;
  }

  public rowSelectionChanged(records: any) {
    const selectedNodes = records?.api?.selectionService?.selectedNodes;
    // removing undefined
    Object.keys(selectedNodes).forEach(
      (key) => selectedNodes[key] === undefined && delete selectedNodes[key]
    );
    this.selectedRecords = selectedNodes;
    this.updateActionforBulkRecords();
  }

  public updateActionforBulkRecords() {
    this.selectedRecordCount = Object.values(this.selectedRecords).length;
    if (this.selectedRecordCount) {
      const records = Object.values(this.selectedRecords);
      // bewlo code to check for seen/unseen
      const seen = records.filter(
        (res: any) => res?.data?.seen === true
      ).length;
      if (records.length !== seen) {
        this.actionOnBulkRecords.seen = null;
      } else {
        this.actionOnBulkRecords.seen = false;
      }

      // below code is to check for completed
      const completed = records.filter(
        (res: any) => res?.data?.completed === true
      ).length;
      if (completed && records.length !== completed) {
        this.actionOnBulkRecords.completed = null;
      } else if (completed) {
        this.actionOnBulkRecords.completed = true;
      } else {
        this.actionOnBulkRecords.completed = false;
      }

      // below code is to check for flag
      const flag = records.filter(
        (res: any) => res?.data?.flag === true
      ).length;
      if (flag && records.length !== flag) {
        this.actionOnBulkRecords.flag = null;
      } else if (flag) {
        this.actionOnBulkRecords.flag = true;
      } else {
        this.actionOnBulkRecords.flag = false;
      }
    }
  }

  public navigateToManageNotification() {
    this.navigation.navigateTo(this.navigation.getManageNotification());
  }

  public get groupTypes() {
    return groupTypes;
  }

  public rowClassRules: RowClassRules = {
    // row style function
    'cusror-pointer-to-AgRow': (params) => {
      return (
        removeSpace(params?.data?.groupType)?.toUpperCase() !==
          groupTypes[groupTypes.error]?.toUpperCase() &&
        removeSpace(params?.data?.groupType)?.toUpperCase() !==
          groupTypes[groupTypes.dataIngestion]?.toUpperCase() &&
        !params?.data?.error
      );
    },
  };

  public get filterOptionsEnum() {
    return filterOptionsEnum;
  }
}
