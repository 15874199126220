import { Injectable } from '@angular/core';

// Declare SockJS and Stomp
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketConfigService {
  public stompClient: any;

  constructor() {}

  // Open connection with the back-end socket
  public connect() {
    const baseUrl = environment.baseAPIEndpoint;
    let socket = new SockJS(
      `${baseUrl}/sharepoint/api/socket`
    );
    let stompClient = Stomp.over(socket);
    return stompClient;
  }
}
