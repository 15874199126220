import { environment } from './../environments/environment';
import config from '../assets/appconfig.json'
export class AppConst {
  static environments={
    Dev:'dev',
    QA:'qa',
    PT:'pt',
    UAT:'uat',
    UAT2:'uat2',
    Local:'local'
  }
  static storageServiceName: string = environment.currentEnv ==this.environments.Dev || environment.currentEnv == this.environments.Local ? 'storageservice' : 'storage'
  static notificationServiceName: string = environment.currentEnv ==this.environments.Dev || environment.currentEnv == this.environments.Local ? 'notificationservice' : 'notification'
  static autheticationServiceName: string = environment.currentEnv ==this.environments.Dev || environment.currentEnv == this.environments.Local ? 'authenticationservice' : 'authentication'

  static API_ENDPOINT: string = environment.baseAPIEndpoint;
  static API_FUND_REQ_ENDPOINT: string = this.API_ENDPOINT + '/fundrequest/api';
  static API_CALCULATION_SERVICE_ENDPOINT: string =
    this.API_ENDPOINT + '/calculation/api';
  static API_WORKPAPER_SERVICE_ENDPOINT: string =
    this.API_ENDPOINT + '/workpaper/api';
  static API_SHAREPOINT_SERVICE_ENDPOINT: string =
    this.API_ENDPOINT + '/sharepoint/api';
  static API_USER_MANAGEMENT_SERVICE_ENDPOINT: string =
    this.API_ENDPOINT + '/usermanagement/api';
  static API_STORAGE_SERVICE_ENDPOINT: string =
    this.API_ENDPOINT + `/${this.storageServiceName}/api`;

  static VERSION_1: string = 'v1';
  static SIGNALR_CONNECTION_ENDPOINT: string =
    this.API_ENDPOINT + `/${this.notificationServiceName}/api/${AppConst.VERSION_1}`;
  static API_MOCK_ENDPOINT: string = 'mock-domain/api';
  static API_AUTHENTICATION_SERVICE_ENDPOINT: string =
    this.API_ENDPOINT + `/${this.autheticationServiceName}/api`;

  static HttpMethods: any = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
  };
  static AG_GRID_LICENSE: string =
    'CompanyName=SHI International Corp._on_behalf_of_Ernst & Young U.S. LLP,LicensedApplication=WAMapps Fund Audit,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-027772,ExpiryDate=23_May_2023_[v2]_MTY4NDc5NjQwMDAwMA==cf00d03eab96d5333389054400ba41e1';

  static paginationPageSize = 10;
  static filesTabPaginationSize=20
  static FRReportFilters: any = {
    administrator: 'administrator',
    myClients: 'myClients',
    closedFunds: 'closedFunds',
  };
  static notificationTimeOut: number = 5000; //in ms
  /* login constancts */
  static EYC_LOGIN: boolean = false;
  static SESSION_ACCESS_TOKEN = 'access_token';
  static SESSION_ENCRYPTION_KEY = config.ENCRYPTION_KEY;
  static SESSION_ENCRYPTION_IV = config.ENCRYPTION_IV;
  // local storage and session storage identifiers
  static LOCAL_STORAGE_ID_CURRENT_USER_SESSION = 'currentUserSession';
  static SESSION_TIMEOUT_TIME = 1800000;
  //added to keep count of no. of times token refreshed
  static NUMBER_OF_TIMES_TOKEN_REFRESHED = 'tokenRefreshCount';
  //added to keep all modules and controls
  static ALL_MODULE_AND_CONTROLS = 'modules';
  static SELECTED_MODULE_AND_CONTROLS = 'attr3';
  //below key added to store session Id
  static SESSION_ID_KEY = 'session-id';
  //eydf portal url
  static EYDF_PORTAL_URL = 'https://fdfu.ey.net/';
  //alternate fund code name
  static alternateFundCodeName = 'Alternate Codes';
  //sharePointFileNames
  static sampleFileName = 'SDMSandbox.zip';
  static deleveryMemoFileName = 'WAMappsV2.8DeliveryMemo-Self-Onboarding.docx';
  //atlas Url for custom mapping
  static atlasRedirectUrl : string = 'https://live.atlas.ey.com/#document/1651360?pref=20011/9/147&layers=0&crumb=103';
  static SELECTED_REGION_ON_DT_DASHBOARD='region'

  static tableLoadingMessage='<span class="ag-overlay-loading-center">Loading...</span>';
  static tableNoRowsMessage='No Data Found';
  static FDF_URL={
    devQa:'https://qfusion.us.ey.com/#/view/home',
    uatPerf:'https://ufusion.us.ey.com/#/view/home'
  }
}
