<motif-form-field>
	<input
	    motifInput
	    motifSearch
	    type="text"
	    placeholder="Search..."
	    aria-label="Search Bar"
	    [hideClear]="true"
		#searchText
		(input)="onFilterTextBoxChanged(searchText.value)"
	/>
</motif-form-field>