import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConst } from '@app/App.const'
import config from './assets/appconfig.json'

import { AppModule } from './app/app.module';

import { LicenseManager } from 'ag-grid-enterprise';
import { environment } from './environments/environment';

const bootstrap = () => {
  return new Promise((resolve, reject) => {
    // fetch('./assets/appconfig.json')
    //   .then((response) => response.json())
    //   .then((res) => {
    //     // res.production ? (AppConst.API_ENDPOINT = res.baseUrl) : null;
    //     // AppConst.SIGNALR_CONNECTION_ENDPOINT = res.signalRServiceUrl;

    //     /** following configuration is for the ag-grid enterprise version */
    //     environment.xApiKey=res.xApiKey
    //     LicenseManager.setLicenseKey(AppConst.AG_GRID_LICENSE);
    //     /** End */

    //     if (res.production) {
    //       enableProdMode();
    //     }
    //     resolve(
    //       platformBrowserDynamic()
    //         .bootstrapModule(AppModule)
    //         .catch((err) => console.error(err))
    //     );
    //   });
    
    environment.xApiKey=config.xApiKey
    LicenseManager.setLicenseKey(AppConst.AG_GRID_LICENSE);
    /** End */

    if (config.production) {
      enableProdMode();
    }
    resolve(
      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err))
    );
  }).catch((err) => {
    return err;
  });
};

bootstrap();
