<motif-header [fixed]="true" appHeaderName="Fund Audit">
  <button
    motifIconButton
    aria-label="Open Main Menu"
    tabindex="0"
    role="button"
    (click)="toggleSidebar(expanded)"
    (keypress.Space)="showMenu = !showMenu"
    placement="left"
  >
    <motif-icon [src]="'/assets/images/hamburger.svg'"></motif-icon>
  </button>
  <!-- Logo -->
  <motif-header-logo>
    <a routerLink="/" tabindex="0" aria-label="Home Page Link">
      <img src="./assets/images/ey-logo-inverted.svg" alt="EY Motif Logo" />
    </a>
  </motif-header-logo>
  <!-- Icons slot -->
  <motif-header-icons-slot>
    
    <!-- logout option -->
    <div class="user-details">{{user?.name?.split(' ')[0]}}
      <span class="user-icon"><motif-icon [src]="'/assets/images/person_outline.svg'"></motif-icon></span>
      <motif-icon style="display: flex;"
        [src]="'/assets/images/down-arrow copy.svg'"
        (click)="showUserDetail = !showUserDetail"
      ></motif-icon>
    </div>
    <!-- add notification -->
    <motif-icon
      (click)="addNotificationTemp()"
      [src]="'/assets/images/notification_add.svg'"
    ></motif-icon>
    <!-- app notifications icon -->
    <motif-icon
      class="app-notification-bell-icon"
      [src]="'/assets/images/notification.svg'"
      (click)="showModal = !showModal"
    >
      <motif-badge
        type="success"
        [number]="notificationCount"
        [overlap]="true"
      ></motif-badge>
    </motif-icon>
  </motif-header-icons-slot>
  <!-- Sidebar -->

  <!-- Menu -->
</motif-header>
<motif-vertical-navigation
  [menu]="menuOptions"
  [expanded]="expanded"
  [config]="config"
>
  <ng-content></ng-content>
</motif-vertical-navigation>

<!-- notification modal -->
<app-modal
  *ngIf="showModal"
  [showModal]="true"
  [top]="60"
  [modalBodyTopPosition]="2"
  [modalBodyRightPosition]="10"
  (closeModal)="showModal = false"
>
  <app-notification (closeModel)="showModal = $event"></app-notification>
</app-modal>
<app-modal
  *ngIf="showUserDetail"
  [showModal]="true"
  [top]="60"
  [modalBodyTopPosition]="2"
  [modalBodyRightPosition]="30"
  [modalBodyWidth]="350"
  (closeModal)="showUserDetail = false"
>
  <app-user-info [user]="user" [logout]="logout.bind(this)" (closeModel)="showUserDetail = $event"></app-user-info>
</app-modal>
<!-- notification-popup -->
<div class="notifications">
  <div *ngFor="let notification of newNotifications" class="notification">
    <ng-container
      *ngTemplateOutlet="
        notificationTpl;
        context: { notification: notification }
      "
    ></ng-container>
  </div>
</div>

<ng-template #notificationTpl let-notification="notification">
  <motif-card
    [ngClass]="{
      'card-expanded':
        notification.isExpanded &&
        (removeSpace(notification.groupType).toUpperCase() ===
          groupTypes[groupTypes.fundRequest].toUpperCase() ||
          removeSpace(notification.groupType).toUpperCase() ===
            groupTypes[groupTypes.workpaper].toUpperCase())
    }"
    (click)="navigateToFundDetails(notification)"
  >
    <motif-card-body>
      <div class="card-header-row">
        <div class="group-type">
          <motif-chip [removeable]="false" variant="success">
            <span class="group-type-name">{{
              notification.groupType
            }}</span></motif-chip
          >
        </div>
        <div class="close-icon">
          <button aria-label="icon-button" (click)="close(notification)">
            <motif-icon
              [src]="'/assets/icons/navigation/ic_close_24px.svg'"
            ></motif-icon>
          </button>
        </div>
      </div>
      <div class="card-footer-row">
        <div class="motif-h6 card-title">
          {{ notification.clientName + " | " + notification.administrator }}
        </div>
        <button
          aria-label="icon-button"
          (click)="toggleNotificationPopUp(notification, $event)"
        >
          <motif-icon
            *ngIf="!notification.isExpanded"
            [src]="'/assets/icons/navigation/ic_expand_more_24px.svg'"
          ></motif-icon>
          <motif-icon
            *ngIf="notification.isExpanded"
            [src]="'/assets/icons/navigation/ic_expand_less_24px.svg'"
          ></motif-icon>
        </button>
      </div>
      <motif-accordion type="editorial" togglePosition="after">
        <motif-expansion-panel [(expanded)]="notification.isExpanded">
          {{ notification.description }}
        </motif-expansion-panel>
      </motif-accordion>
    </motif-card-body>
  </motif-card>
</ng-template>
