
<div class="user-details-container">
    <div (click)="handleCloseClick()">
        <motif-icon class='close-icon'[src]="'/assets/images/close-icon.svg'" ></motif-icon>
    </div>
    <div class='user-detail'>
        <div class="user-email">{{user?.email}}</div>
        <div class="user-name">First Name: {{getFirstName()}}</div>
        <div class="user-name last-name">Last Name: {{getLastName()}}</div>
        <div class="logout" (click)="logout()">
            Logout <motif-icon [src]="'/assets/icons/action/ic_exit_to_app_24px.svg'"></motif-icon>
        <div>
    </div>
</div>