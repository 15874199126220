import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AppConst } from '@app/App.const';
import { Observable } from 'rxjs';
import { NavigationService } from '../../navigation/navigation.service';
import { decryptData, encryptData } from '../../utils/app-utils';
import { SettingsService } from '../Authentication/settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  public selectedModule: string = '';

  constructor(
    private settinService: SettingsService,
    private navigationServie: NavigationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    if (!this.settinService.isUserLoggedin()) {
      this.navigationServie.navigateTo(this.navigationServie.getLoginUrl());
      return false;
    } else {
      this.selectedModule = '';
      const encryptPermissions = sessionStorage.getItem(
        AppConst.ALL_MODULE_AND_CONTROLS
      );
      const permissions = encryptPermissions
        ? JSON.parse(decryptData(encryptPermissions) as any)
        : null;
      // const activeModuleRoute = state.url.replace(/\//g, '');
      const activeModuleRoute = state?.url.replace(/^\//, '')?.split('/')?.[0];
      switch (activeModuleRoute) {
        case 'dashboard':
          this.selectedModule = 'FR Upload';
          break;
        case 'dt-dashboard':
          this.selectedModule = 'Fund Listing';
          break;
        case 'custom-mapping':
          this.selectedModule = 'Live Mapping';
          break;
        case 'templates':
          this.selectedModule = 'Templates';
          break;
        case 'administrationfr':
          this.selectedModule = 'Administration';
          break;
        case 'administration':
          this.selectedModule = 'Administration';
          break;
        case 'auditor-dashboard':
          this.selectedModule = 'Auditor Dashboard';
          break;
        case 'configuration':
          this.selectedModule = 'Configuration';
          break;
        case 'user-access-management':
          this.selectedModule = 'User Access Management';
          break;
        case 'self-onboarding':
          this.selectedModule = 'Self Onboarding';
          break;
        case 'all-notification':
          this.selectedModule = 'Notifications';
          break;
        case 'manage-notification':
          this.selectedModule = 'ManageNotifications';
          break;
        case 'home':
          this.selectedModule='Home'
          break
      }
      const moduleData = permissions?.find(
        (obj: any) => obj.moduleName === this.selectedModule
      );

      if (this.selectedModule && moduleData) {
        const currentModule: any = encryptData(
          JSON.stringify({
            moduleName: moduleData?.moduleName,
            featureCodes: moduleData?.featureCodes,
          })
        );
        sessionStorage.setItem(
          AppConst.SELECTED_MODULE_AND_CONTROLS,
          currentModule
        );
        return true;
      } else {
        return false;
      }
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
}
