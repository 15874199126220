<app-header *ngIf="showHeaderFooter; else loginScreen">
  <section class="page-container">
    <div class="toaster-container"><app-toaster></app-toaster></div>
    <ng-content></ng-content>
  </section>
</app-header>

<!-- else for showing login page -->
<ng-template #loginScreen>
  <router-outlet></router-outlet>
</ng-template>

<motif-progress-loader
  [show]="showLoader"
  [fullScreen]="true"
></motif-progress-loader>
