import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent  {
  @Input() public showModal = false;
  @Output() public closeModal = new EventEmitter<Event>();
  @Input() public top = 0;
  @Input() public modalBodyTopPosition = 0;
  @Input() public modalBodyRightPosition = 0;
  @Input() public modalBodyWidth = 450;


  /**
   *
   *closeModalPopUp: Close the modal by emitting the event.
   * @memberof ModalComponent
   */
  public closeModalPopUp() {
    this.closeModal.emit();
  }
}
