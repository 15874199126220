import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { AppConst } from '../../../App.const';
import { notificationJSON } from '../utils/app-interface';
import { ApiEndpointsService } from './api-endpoint.service';
import { ApiHttpService } from './api-http.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRConnectionService {
  private _notifications = new BehaviorSubject<notificationJSON[]>([]);
  private _unreadNotifications = new BehaviorSubject<notificationJSON[]>([]);
  private _unreadNotificationCount = new BehaviorSubject<number>(0);
  private _newNotification = new BehaviorSubject<notificationJSON | null>(null);
  private hubConnection!: HubConnection;

  get notifications() {
    return this._notifications;
  }

  get unreadNotifications() {
    return this._unreadNotifications;
  }

  get unreadNotificationCount() {
    return this._unreadNotificationCount;
  }

  get newNotification() {
    return this._newNotification;
  }

  constructor(
    private ApiEndPoint: ApiEndpointsService,
    private Api: ApiHttpService
  ) {}

  public getAllNotifications() {
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.ApiEndPoint.getAllNotifications(),
      null
    ).subscribe((res: any) => {
      if (res?.length) {
        let unreadNotifications: notificationJSON[] = [];
        res.forEach((notification: notificationJSON) => {
          notification.isExpanded = false;
          if (notification.seen === false) {
            unreadNotifications.push(notification);
          }
        });
        this.updateAllSubjects(res, unreadNotifications);
      }
    });
  }

  public updateAllSubjects(
    notifications: notificationJSON[] = [],
    unreadNotifications: notificationJSON[] = []
  ) {
    if (notifications.length) {
      this._unreadNotificationCount.next(unreadNotifications.length);
      this._notifications.next(notifications);
    }
    if (unreadNotifications.length) {
      this._unreadNotifications.next(unreadNotifications);
    }
  }

  public refreshNotifications() {
    // this.getAllNotifications();
  }

  public appendNotification(notification: notificationJSON) {
    notification.isExpanded = false;
    this._notifications.value.push(notification);
    if (notification.seen === false) {
      this._unreadNotifications.value.push(notification);
    }
    this.updateAllSubjects(
      this.notifications.value,
      this.unreadNotifications.value
    );
  }

  public clearNotifications() {
    this._notifications.next([]);
    this._unreadNotificationCount.next(0);
  }

  public buildSignalRConnection() {
    this.getSignalRConnection();
  }

  private getSignalRConnection() {
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.ApiEndPoint.getSignalRConnectionUrl(),
      {}
    ).subscribe((data) => {
      if (data) {
        this.startConnection(data);
        this.addListeners();
      }
    });
  }

  private startConnection(data: any) {
    try {
      this.hubConnection = this.getConnection(data);
      this.hubConnection
        .start()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  }

  private getConnection(data: any): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(`${data.hubUrl}`, {
        accessTokenFactory: () => `${data.accessKey}`,
      })
      .build();
  }

  private addListeners() {
    this.hubConnection.on('newMessage', (notification: any) => {
      console.log('from signalR service');
      console.log(notification);
      if (notification) {
        this._newNotification.next(notification);
        //this.setNotificationInlocalstorage(notification);
      }
    });
  }

  //added for demo purpose
  counter: string = '0';
  public addNotificationTemp() {
    this._newNotification.next({
      clientName: 'HDFC Argo',
      administrator: 'Statestreet',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation sed do eiusmod tempor incidijug ad minimalisi sed do sis',
      dateTime: '2017-01-01T00:00:00.000+00:00',
      groupType: 'Fund Request',
      error: false,
      notificationActionId: (this.counter += '1'),
      fundName: 'abc',
      aggConsoleFlag: 'Aggregation',
      isParent: false,
      fundRequestId: '1',
      seen: false,
      completed: false,
      flag: true,
    });
  }
}
