import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { ApiHttpService } from '../api-http.service';
import { AppConst } from '@app/App.const';
import { ApiEndpointsService } from '../api-endpoint.service';
import { Observable } from 'rxjs';
import { ToasterService } from '../toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService{
  
  regionConfigUpdated = new EventEmitter<boolean>();

  constructor(
    private Api: ApiHttpService,
    private apiEndPoint: ApiEndpointsService,
    private toastService: ToasterService
    ){}
  public frValidationConfig: any;
  public regionDateFormat:any;
  private regionListWithFormat: any=[];
  private possibleDateFormatList: any=[];

  get getRegionListWithFormat(){
    return JSON.parse(JSON.stringify(this.regionListWithFormat));
  }

  get getPossibleDateFormatList(){
    return JSON.parse(JSON.stringify(this.possibleDateFormatList));
  }

  private updateRegionDateFormat(format:string='dd/mm/yyyy'){
    this.regionDateFormat=format;
    localStorage.setItem('regionDateFormat',format);
  }

  public getFRValidationConfigurations(observableRequired: boolean = false) {
    const observable: Observable<any> = this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getFRValidationConfig(),
      null
    );
    if (observableRequired) {
      return observable;
    } else {
      observable.subscribe((data: any) => {
        if (data) {
          this.frValidationConfig = Object.assign([], data);
        }
        else{
          this.frValidationConfig = null;
        }
      });
      return this.frValidationConfig;
    }
  }
  public getRegionConfig(observableRequired=false){
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getRegionConfigURL(),
      null
    ).subscribe((res) =>{
        if(res){
          this.possibleDateFormatList = res.allowableDateFormats?.map((format:any)=>format.trim())
          this.regionListWithFormat=res.dateFormatsPerRegion;
          this.regionConfigUpdated.emit(true);
        }
      })
  }

  public setRegionDateFormat(selectedRegion:string){
    const region=this?.regionListWithFormat?.find((data:any)=>data.region.toLowerCase()===selectedRegion.toLowerCase());
    if(region){
      this.updateRegionDateFormat(region.selectedDateFormat);
    }
  }

  public updateRegionConfig(body:any){
    this.Api.callApi(
      AppConst.HttpMethods.POST,
      this.apiEndPoint.updateRegionConfigURL(),
      body
    ).subscribe((res) =>{
      if(res.status=='200'){
        this.toastService.showToasterSuccess('Region Configuration Updated Successfully');
      }
      this.getRegionConfig();
    })
  }
}
