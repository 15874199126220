import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageNotificationComponent } from './layout/mainframe/page/header/notification/manage-notification/manage-notification.component';
import { NotificationAllComponent } from './layout/mainframe/page/header/notification/notification-all/notification-all.component';
import { LoginComponent } from './login/login.component';
import { SelfOnboardingComponent } from './self-onboarding/self-onboarding.component';
import { AuthGuardService } from './shared/common/services/AuthGuard/auth-guard.service';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { 
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-notification',
    component: NotificationAllComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-notification',
    component: ManageNotificationComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('@app/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'custom-mapping',
    loadChildren: () =>
      import('./custom-mapping/custom-mapping.module').then(
        (m) => m.CustomMappingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('@app/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('@app/templates/templates.module').then((m) => m.TemplatesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'auditor-dashboard',
    loadChildren: () =>
      import('@app/auditor-dashboard-layout/auditor-dashboard.module').then(
        (m) => m.AuditorDashboardModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'dt-dashboard',
    loadChildren: () =>
      import('./dt-dashboard/dt-dashboard.module').then(
        (m) => m.DtDashboardModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('./configuration/configuration.module').then(
        (m) => m.ConfigurationModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user-access-management',
    loadChildren: () =>
      import('@app/user-access-management/user-access-management.module').then(
        (m) => m.UserAccessManagementModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'self-onboarding',
    component: SelfOnboardingComponent,
    canActivate: [AuthGuardService],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
