import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customEllipsis',
})
export class CustomEllipsisPipe implements PipeTransform {
  transform(value: string, customLength: number = 10): string {
    if (value) {
      if (value.length > customLength) {
        return value.slice(0,customLength) + '...';
      }
      return value;
    }
    return '';
  }
}
