import { Component } from '@angular/core';
import { AppConst } from '@app/App.const';
import { MenuItem } from '@app/shared/common/models/menu-item.model';
import { NavigationService } from '@app/shared/common/navigation/navigation.service';
import { SettingsService } from '@app/shared/common/services/Authentication/settings.service';
import { ModuleLevelPermissionService } from '@app/shared/common/services/Permission/module-level-permission.service';
import { CommonMethodsService } from '@app/shared/common/services/common-methods.service';
import { SignalRConnectionService } from '@app/shared/common/services/signal-r-connection.service';
import { groupTypes } from '@app/shared/common/utils/app-enum';
import { notificationJSON } from '@app/shared/common/utils/app-interface';
import { removeSpace } from '@app/shared/common/utils/app-utils';
import {
  VerticalNavigationConfig,
  VerticalNavigationMenuList,
} from '@ey-xd/ng-motif';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  config: VerticalNavigationConfig = {
    position: 'fixed',
    offset: {
      top: 62,
    },
    width: {
      default: 54,
      expanded: 305,
    },
    height: 'calc(100vh - 57px)',
  };
  public expanded = false;

  //Append/remove new options here
  public menuOptionsCopy: VerticalNavigationMenuList = [
    new MenuItem(
      'Fund Listing',
      '/assets/icons/action/ic_dashboard_24px.svg',
      'internal',
      '/dt-dashboard'
    ),
    new MenuItem(
      'FR Upload',
      '/assets/images/upload-square.svg',
      'internal',
      '/dashboard'
    ),
    new MenuItem(
      'Live Mapping',
      '/assets/images/account_tree.svg',
      'internal',
      '/custom-mapping'
    ),
    new MenuItem(
      'Templates',
      '/assets/images/template_icon.svg',
      'internal',
      '/templates'
    ),
    // new MenuItem(
    //   'Administration',
    //   '/assets/images/fr_assignment_icon.svg',
    //   'internal',
    //   'administration/fr'
    // ),
    new MenuItem(
      'Auditor Dashboard',
      '/assets/icons/action/ic_dashboard_24px.svg',
      'internal',
      '/auditor-dashboard'
    ),
    new MenuItem(
      'Configuration',
      '/assets/images/configuration_icon.svg',
      'internal',
      '/configuration'
    ),
    new MenuItem(
      'User Access Management',
      '/assets/images/uam_icon.svg',
      'internal',
      'user-access-management'
    ),
    // new MenuItem(
    //   'Self Onboarding',
    //   'assets/images/self_onboarding.svg',
    //   'internal',
    //   '/self-onboarding'
    // ),
  ];

  public menuOptions: VerticalNavigationMenuList = [];

  public user:any={}
  public showUserDetail = false;
  public showMenu = false;
  public showModal = false;
  public notificationCount: number = 0;
  public newNotifications: notificationJSON[] = [];
  public isAccordionExpanded: boolean = false;

  constructor(
    public signalRService: SignalRConnectionService,
    private SignalRConnectionService: SignalRConnectionService,
    private navigation: NavigationService,
    private settingsService: SettingsService,
    private permissionService: ModuleLevelPermissionService,
    private commonService: CommonMethodsService,
  ) {
    this.SignalRConnectionService.unreadNotificationCount?.subscribe(
      (notificationCount: number) => {
        this.notificationCount = notificationCount;
      }
    );

    this.SignalRConnectionService.newNotification?.subscribe(
      (notification: notificationJSON | null) => {
        if (notification) {
          // for future reference // this.SignalRConnectionService.refreshNotifications();
          this._addNotification(notification);
        }
      }
    );
  }

  ngOnInit() {
    // this.signalRService.buildSignalRConnection();
    this.permissionService.moduleLevelPermisssionDetails.subscribe((res) => {
      this.filterSideNavEntries(res);
    });
    // subscribe to user details call from common service
    this.commonService.userDetail.subscribe((res) => {
      this.user = res;
      this.settingsService.setUserName(this.user.name.split(' ')[0])
    })
    // for future reference //signalRService.totalNotificationCount
  }

  public filterSideNavEntries(permissions: any) {
    const menuOptionsList: any = [new MenuItem(
      'Home',
      'assets/icons/action/ic_home_24px.svg',
      'internal',
      '/home'
    ),];
    (this.menuOptionsCopy as any).forEach(
      (menuOption: VerticalNavigationMenuList[0]) => {
        if (
          permissions?.some((obj: any) => obj.moduleName === menuOption.text)
        ) {
          menuOptionsList.push(menuOption);
        }
      }
    );
    this.menuOptions = menuOptionsList;
  }

  public toggleSidebar(value: boolean) {
    this.expanded = !value;
  }

  public _addNotification(notification: notificationJSON) {
    this.newNotifications.push(notification);
    // for future reference // this.SignalRConnectionService.getAllNotifications();
    this.SignalRConnectionService.appendNotification(notification);
    if (notification) {
      setTimeout(() => {
        if (!notification.isExpanded) {
          this.close(notification);
        }
      }, AppConst.notificationTimeOut);
    }
  }

  public close(notification: notificationJSON) {
    this.newNotifications = this.newNotifications.filter(
      (notif: notificationJSON) =>
        notif.notificationActionId !== notification.notificationActionId
    );
  }

  public addNotificationTemp() {
    this.SignalRConnectionService.addNotificationTemp();
  }

  public navigateToFundDetails(notification: notificationJSON) {
    if (notification.isExpanded) {
      this.navigation.navigateWithQueryParam(
        this.navigation.getAuditorFundDetails(
          notification.fundRequestId,
          notification.fundName,
          notification.administrator,
          notification.aggConsoleFlag
        ),
        { isParent: notification.isParent }
      );
      this.close(notification);
    }
  }

  public toggleNotificationPopUp(notification: notificationJSON, event: any) {
    notification.isExpanded = !notification.isExpanded;
    event.stopPropagation();
  }

  public get groupTypes() {
    return groupTypes;
  }

  public removeSpace(inp: string) {
    return removeSpace(inp);
  }

  public logout() {
    this.settingsService.logoff();
  }
}
