import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(environment.baseAPIEndpoint) ||
      request.url.includes('https://graph.microsoft.com/v1.0/users')
    ) {
      this.loaderService.showLoader();
    }
    //As per the request this api might take longer to respond hence not to keep screen in loading state
    if(request.url.includes('/fundrequest/download-options')){
      this.loaderService.hideLoader();
    }
    return next
      .handle(request)
      .pipe(finalize(() => this.loaderService.hideLoader()));
  }
}
