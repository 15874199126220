import { Component, OnInit } from '@angular/core';
import { AppConst } from '@app/App.const';
import { NavigationService } from '@app/shared/common/navigation/navigation.service';
import { SettingsService } from '@app/shared/common/services/Authentication/settings.service';
import { CommonMethodsService } from '@app/shared/common/services/common-methods.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public userName:string=''
  constructor(
    private settingsService: SettingsService,
    private navigation:NavigationService,
    private commonMethodsService:CommonMethodsService
  ) { }

  ngOnInit(): void {
    this.userName=this.settingsService.getUserName()
  }

  public linkNames={
    uploadAndTrackFR:'Upload and track fund request statuses',
    selfOnboarding:'Self-Onboarding',
    atlas:'WAMapps Enablement – Atlas',
    liveMapping:'Live mappings',
    SPHub:'SharePoint Hub',
    createFR:'Create Fund Request',
    selfOnboardingTutorials:'Self-Onboarding Tutorials',
    customMappingGuide:'Custom Mapping Guide',
    trainingLinks:'Trainings Link',
    sanatizedOuputs:'Sanitized Outputs- Version 3.0'
    //SDSandbox:'Standard Data Sandbox'

  }
  public links1=[
    {
      "main-icon":'/assets/images/db-check.svg',
      "name":this.linkNames.createFR,
      "link-icon":'/assets/images/arrow_forward.svg'
    },
    {
      "main-icon":'/assets/images/db-check.svg',
      "name":this.linkNames.uploadAndTrackFR,
      "link-icon":'/assets/images/arrow_forward.svg'
    },
    {
      "main-icon":'/assets/images/db-check.svg',
      "name":this.linkNames.liveMapping,
      "link-icon":'/assets/images/arrow_forward.svg'
    }
  ]
  public links2=[
    {
      "main-icon":'/assets/images/tools.svg',
      "name":this.linkNames.selfOnboarding,
      "link-icon":'/assets/images/ic_open.svg'
    },
    {
      "main-icon":'/assets/images/link.svg',
      "name":this.linkNames.atlas,
      "link-icon":'/assets/images/ic_open.svg'
    },
    {
      "main-icon":'/assets/images/link.svg',
      "name":this.linkNames.SPHub,
      "link-icon":'/assets/images/ic_open.svg'
    }
  ]
  public guides=[
    {
      "name":this.linkNames.selfOnboardingTutorials,
      "icon":"/assets/images/ic_open.svg"
    },
    {
      "name":this.linkNames.customMappingGuide,
      "icon":"/assets/images/ic_open.svg"
    },
    {
      "name":this.linkNames.trainingLinks,
      "icon":"/assets/images/ic_open.svg"
    },
    {
      "name":this.linkNames.sanatizedOuputs,
      "icon":"/assets/images/ic_open.svg"
    }
  ]
  public footerLinks=[
    {
      "name":"Legal and Privacy"
    },
    {
      "name":"Connect with Us"
    },
    {
      "name":"EY Client Portal"
    },
    {
      "name":"Our Locations"
    },
    {
      "name":"Site Map"
    }
  ]

  public handleClick(link:any){
    const currentEnv=environment.currentEnv
    let url=''
    switch(link.name){
      case this.linkNames.liveMapping:
        this.navigation.navigateTo(this.navigation.getLiveMappingRoute);
        break;
      case this.linkNames.uploadAndTrackFR:  
        this.commonMethodsService.getUserDetailsWithPersona()?.subscribe((user)=>{
          if(user?.persona?.toLowerCase()?.includes('auditor')){
            this.navigation.navigateTo(this.navigation.getAuditorDashboard())
          }
          else{
            this.navigation.navigateTo(this.navigation.getDtFundListingRoute)
          }
        })
        break;
      case this.linkNames.atlas:
        window.open('https://live.atlas.ey.com/#document/1651360?pref=20052/9/1007','_blank')
        break
      case this.linkNames.SPHub:
        window.open('https://sites.ey.com/sites/WAMapps','_blank')
        break
      case this.linkNames.createFR:
        this.navigation.navigateTo(this.navigation.createFundRequestRoute);
        break
      case this.linkNames.selfOnboarding:
        url= currentEnv==AppConst.environments.QA || currentEnv==AppConst.environments.Dev ? AppConst.FDF_URL.devQa : AppConst.FDF_URL.uatPerf 
        window.open(url,'_blank')
        break
      case this.linkNames.selfOnboardingTutorials:
        url= currentEnv==AppConst.environments.QA || currentEnv==AppConst.environments.Dev ? AppConst.FDF_URL.devQa : AppConst.FDF_URL.uatPerf 
        window.open(url,'_blank')
        break
      case this.linkNames.customMappingGuide:
        url='https://live.atlas.ey.com/#document/2040181?pref=20052/9/1007&crumb=1651360'
        window.open(url,'_blank')
        break
      case this.linkNames.trainingLinks:
        url='https://sites.ey.com/sites/WAMapps/SitePages/How-To-Videos---WAMapps-Tools.aspx?csf=1&web=1&e=gCgIh9'
        window.open(url,'_blank')
        break
      case this.linkNames.sanatizedOuputs:
        url='https://sites.ey.com/sites/WAMapps/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=JhqOen&CID=76de0626%2D1eba%2D4163%2Daa89%2Dff0f71fc99d3&FolderCTID=0x010100826318CDA76982469C2C3CD2CD584741010100B6582C9C3C0D10478FB8DEA06D8812E8&id=%2Fsites%2FWAMapps%2FShared%20Documents%2FV2%2E9'
        window.open(url,'_blank')
        break

    }
  }

}
