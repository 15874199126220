import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { environment } from './../../../../environments/environment';
import { Observable } from 'rxjs';
import { SettingsService } from '../services/Authentication/settings.service';
import { AppConst } from '@app/App.const';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private settingServie: SettingsService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(environment.baseAPIEndpoint) ||
      request.url.includes('https://graph.microsoft.com/v1.0/users')
    ) {
      let currentUserToken = sessionStorage.getItem(
        AppConst.SESSION_ACCESS_TOKEN
      );
      if (request.url.includes('https://graph.microsoft.com/v1.0/users')) {
        currentUserToken = this.settingServie.getToken();
      }
      let sessionId = sessionStorage.getItem(AppConst.SESSION_ID_KEY);
      sessionId = sessionId ? sessionId : '';
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserToken}`,
          'x-api-key': environment?.xApiKey || '',
          'X-Correlation-ID': uuidv4(),
          'session-id': sessionId,
        },
      });
    }
    return next.handle(request);
  }
}
