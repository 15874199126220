<div class="home-page">
    <div class="upper-section" style="height: 300px;">
        <div class="main-image">
            <img style="width: 100%;" src="/assets/images/dashboard.png" alt="Dashboard">
        </div>
        <div class="version-detail">
            <div class="release-image"><motif-icon src="/assets/images/ic_new_releases.svg"></motif-icon></div>
            <div class="version">
                <div class="name">
                    <span style="font-weight: 700;">Version 3.0 released</span>
                </div>
                <div class="date"><span>September 2024</span></div>
            </div>
        </div>
        <div class="welcome-note">
            <span class="userName">Welcome {{userName}}</span>
            <span>Welcome to WAMapps, EY's global fund audit technology platform</span>
        </div>
        <div class="stamp">
            <img src="/assets/images/stamp.png" alt="global approval stamp">
        </div>
    </div>
    <div class="middle-section">
        <div class="left">
            <div class="link" *ngFor="let link of links1" (click)="handleClick(link)">
                <div class="left-part">
                    <div class="icon-outer-layer">
                        <div class="main-icon">
                            <motif-icon [src]="link['main-icon']"></motif-icon>
                        </div>
                    </div>
                    <div style="margin-left: 1rem;">{{link.name}}</div>
                </div>
                <div style="padding: 1rem;">
                    <div class="link-icon" >
                        <motif-icon [src]="link['link-icon']"></motif-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="left" >
            <div class="link" *ngFor="let link of links2" (click)="handleClick(link)">
                <div class="left-part">
                    <div class="icon-outer-layer">
                        <div class="main-icon">
                            <motif-icon [src]="link['main-icon']"></motif-icon>
                        </div>
                    </div>
                    <div style="margin-left: 1rem;">{{link.name}}</div>
                </div>
                <div style="padding: 1rem;">
                    <div class="link-icon" >
                        <motif-icon [src]="link['link-icon']"></motif-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="header">
                <div style="margin: 0rem 1rem;">
                   <motif-icon [src]="'/assets/images/media-video-list.svg'"></motif-icon>
                </div>
                <span>Guides and Trainings</span>
            </div>
            <div class="guide" *ngFor="let guide of guides" (click)="handleClick(guide)">
                <div> <span>{{guide.name}}</span></div>
                <div style="padding: 1rem;">
                    <div class="link-icon" >
                        <motif-icon [src]="guide['icon']"></motif-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mail-info">
        <div class="icon-mail">
            <motif-icon [src]="'/assets/images/mail.svg'"></motif-icon>
        </div>
        <div><span>For additional questions or access issue to any of the files above, feel free to contact us at
            <a style="cursor: pointer; color: #155CB4;" href="mailto:info.wamapps@ie.ey.com">info.wamapps&#64;ie.ey.com</a>
        </span></div>
    </div>
    <div class="footer">
        <div class="left-footer">
            <motif-icon [src]="'/assets/images/EY Logo.svg'"></motif-icon>
            <div style="display: flex;flex-direction: column;"> 
                <span>EY refers to the global organization, and may refer to one or more, of the member firms of Ernst & Young Global Limited,each of which is a separate legal entity. Ernst & Young Global Limited, a UK company limited by guarantee, does not provide services to clients.</span>
            </div>
        </div>
        <div class="right-footer">
            <div *ngFor="let link of footerLinks">
                <span>{{link["name"]}}</span>
            </div>
        </div>
    </div>
</div>
