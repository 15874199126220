<motif-card style="height: auto">
  <motif-card-header>
    <div class="motif-row">
      <div
        class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4 space-between-flex-class"
      >
        <span class="motif-h3"><strong>Notifications</strong></span>
        <span class="current-date">{{
          currentDateTime | date : "MM-dd-yy, h:mm aa"
        }}</span>
      </div>
    </div>
  </motif-card-header>
  <motif-card-body>
    <div class="card-body-class">
      <div class="motif-row">
        <div
          class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4 space-between-flex-class"
        >
          <span class="checkbox-container">
            <!-- categorise -->
            <motif-checkbox
              (change)="categorizeChecked()"
              [(ngModel)]="isCategorized"
              >Categorize</motif-checkbox
            >
            <!-- is Error -->
            <motif-checkbox
              *ngIf="isCategorized"
              (change)="categorizeChecked()"
              [(ngModel)]="isErrorIncluded"
              >Error</motif-checkbox
            >
          </span>
          <span
            (click)="navigateToAllNotifications()"
            class="all-notification-navigation"
            >All Notifications</span
          >
        </div>
      </div>
      <div *ngIf="notifications.length; else noNotification">
        <!-- for checkbox isCategorized = false-->
        <div *ngIf="!isCategorized; else isCategorizedView">
          <div class="motif-row">
            <div
              class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4 notification-group-container"
            >
              <motif-card
                class="notification-group-type-card"
                *ngFor="let notification of notifications; let index = index"
              >
                <div
                  class="notification-grid-card"
                  (click)="notification.isExpanded = !notification.isExpanded"
                >
                  <div
                    [ngClass]="{
                      'notification-avatar': true,
                      'notification-avatar-expanded': notification.isExpanded
                    }"
                  >
                    <motif-avatar
                      [ngClass]="{
                        'group-type-avatar': !notification.error,
                        'group-type-avatar-error': notification.error
                      }"
                      [userName]="notification.groupType"
                      [size]="'small'"
                    ></motif-avatar>
                  </div>
                  <div class="notification-info">
                    <div
                      class="client-info-administrator"
                      [title]="
                        notification.clientName +
                        ' | ' +
                        notification.administrator
                      "
                    >
                      {{ notification.clientName | customEllipsis : 20 }}
                      {{ " | " }}
                      {{ notification.administrator | customEllipsis : 20 }}
                    </div>
                  </div>
                  <div class="notification-description">
                    <span *ngIf="!notification.isExpanded">{{
                      notification.description | customEllipsis : 30
                    }}</span>
                    <!-- {{ notification.description | customEllipsis : 1000 }} -->
                    <motif-accordion [togglePosition]="'after'">
                      <motif-expansion-panel
                        (expandedChange)="
                          notification.isExpanded = !notification.isExpanded
                        "
                        [(expanded)]="notification.isExpanded"
                      >
                        {{ notification.description }}
                      </motif-expansion-panel>
                    </motif-accordion>
                  </div>
                  <div class="notification-date-info">
                    <div class="date-info">
                      {{ notification.dateTime | date : "dd/MM/yyyy" }}
                    </div>
                  </div>
                  <div class="notification-collapse-icon">
                    <button *ngIf="!notification.isExpanded">
                      <motif-icon
                        [src]="
                          '/assets/icons/hardware/ic_keyboard_arrow_down_24px.svg'
                        "
                      ></motif-icon>
                    </button>
                    <div
                      class="button-container-collapse"
                      *ngIf="notification.isExpanded"
                    >
                      <button>
                        <motif-icon
                          [src]="
                            '/assets/icons/hardware/ic_keyboard_arrow_up_24px.svg'
                          "
                        ></motif-icon>
                      </button>
                      <button
                        [hidden]="
                          notification.error ||
                          !(
                            removeSpace(
                              notification.groupType
                            ).toUpperCase() ===
                              groupTypes[
                                groupTypes.fundRequest
                              ].toUpperCase() ||
                            removeSpace(
                              notification.groupType
                            ).toUpperCase() ===
                              groupTypes[groupTypes.workpaper].toUpperCase()
                          )
                        "
                        (click)="
                          navigateToFundDetailsPage(notification, $event)
                        "
                      >
                        <motif-icon
                          [src]="
                            '/assets/icons/hardware/ic_keyboard_arrow_right_24px.svg'
                          "
                        ></motif-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </motif-card>
            </div>
          </div>
        </div>
        <!-- for checkbox isCategorized = true-->
        <ng-template #isCategorizedView>
          <div class="motif-row">
            <div
              class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4 notification-group-container"
            >
              <!-- for checkbox isCategorized = true-->
              <motif-card
                [ngClass]="{
                  'notification-group-type-card-isCategorized': true,
                  'hide-group-type-card':
                    !groupType.notificationsByGroupTypeCount &&
                    groupType.id === groupTypes.error
                }"
                *ngFor="let groupType of groupOnType"
              >
                <div
                  class="notification-card"
                  (click)="navigateToAllNotifications(groupType.id)"
                >
                  <span class="notification-title"
                    ><strong>{{ groupType.title }}</strong></span
                  >
                  <div class="notification-card-right">
                    <span class="notification-count"
                      >{{
                        groupType.notificationsByGroupTypeCount.toString()
                          .length === 1
                          ? "0" + groupType.notificationsByGroupTypeCount
                          : groupType.notificationsByGroupTypeCount
                      }}
                    </span>
                    <motif-icon
                      [src]="
                        '/assets/icons/hardware/ic_keyboard_arrow_right_24px.svg'
                      "
                    ></motif-icon>
                  </div>
                </div>
              </motif-card>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-template #noNotification>
        <div class="no-notification-availabele">
          <span class="no-new-notification">No New Notifications</span>
        </div>
      </ng-template>
    </div>
  </motif-card-body>
</motif-card>
