import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  PlatformLocation,
  registerLocaleData,
} from '@angular/common';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';

import { AppComponent } from './app.component';
import { LayoutComponents } from './layout/all';
import { MotifModule } from '@app/motif.module';
import { SharedModule } from '@app/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppBootstrap } from './AppBootstrap';
import { LoaderInterceptor } from './shared/common/interceptors/loader.interceptor';
import { QuillModule } from 'ngx-quill';
import { LoginModule } from './login/login.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SettingsService } from './shared/common/services/Authentication/settings.service';
import { TokenInterceptor } from './shared/common/interceptors/token.interceptor';
import { WebSocketConfigService } from './shared/common/services/Authentication/web-socket-config.service';
import { UserInfoComponent } from './layout/mainframe/page/header/user-info/user-info.component';
import { HomeModule } from './home/home.module';

export function appInitializerFactory(
  injector: Injector,
  platformLocation: PlatformLocation
) {
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      AppBootstrap.run(injector, (userLoggedIn) => {
        if (userLoggedIn?.lang) {
          try {
            setLocale(resolve, true, reject, userLoggedIn);
          } catch (error) {
            reject(error);
          }
        } else {
          resolve(true);
        }
      });
    });
  };
}

function setLocale(
  resolve: (value: boolean | PromiseLike<boolean>) => void,
  result: boolean,
  reject: (reason?: any) => void,
  userLoggedIn: { lang: string }
) {
  if (shouldLoadLocale(userLoggedIn)) {
    const lang = userLoggedIn.lang;
    import(`@/../@angular/common/locales/${lang}.mjs`).then((module) => {
      registerLocaleData(module.default);
      resolve(result);
    }, reject);
  } else {
    resolve(result);
  }
}

function shouldLoadLocale(userLoggedIn: any) {
  return userLoggedIn.lang !== 'en-US';
}

@NgModule({
  declarations: [AppComponent, ...LayoutComponents, UserInfoComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    MotifModule,
    HttpClientModule,
    HomeModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    LoginModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    WebSocketConfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [Injector, PlatformLocation],
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: resourceProviderFactory,
      deps: [SettingsService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}

export function resourceProviderFactory(provider: SettingsService) {
  return () => provider.loadAuthDetails();
}
