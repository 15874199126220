import moment from "moment";
import { dateConverterToDDMMYYYY, dateTimeFormatter, getDateFormat } from "./app-utils";

const dateComparator = (filterLocalDateAtMidnight: Date, cellValue: string) => {
  let dateAsString = cellValue;
  let dateParts = dateAsString.split('/');
  let cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return null;
};

const dateComparatorWithTime = (filterLocalDateAtMidnight: Date, cellValue: string) => {
  let dateAsString =dateTimeFormatter({"value":cellValue}).split(' ')[0];
  let dateInDDMMYYYY = dateConverterToDDMMYYYY(dateAsString)
  return dateComparator(filterLocalDateAtMidnight, dateInDDMMYYYY)
};

//Note: do not export defaultFilters directly, use getdefaultFilters instead
const defaultFilters={
  Reset: {
    name: 'Reset',
    model: null
  },
  Pending: {
    name: 'Pending',
    model: {
      "fundRequestStatus": {
        "filterType": "multi",
        "filterModels": [
          null,
          {
            "values": ["Pending"],
            "filterType": "set"
          }
        ]
      }
    }
  },
  Open: {
    name: 'Open',
    model: {
      "fundRequestStatus": {
        "filterType": "multi",
        "filterModels": [
          null,
          {
            "values": ["Open"],
            "filterType": "set"
          }
        ]
      }
    }
  },
  Deleted: {
    name: 'Deleted',
    model: {
      "fundRequestStatus": {
        "filterType": "multi",
        "filterModels": [
          null,
          {
            "values": ["Deleted"],
            "filterType": "set"
          }
        ]
      }
    }
  },
}
const getdefaultFilters=()=>{
  return defaultFilters;
}

const setLocalFilter=(model: any) =>{
  localStorage.setItem('filterModel', JSON.stringify(model));
  if(model==null || Object.keys(model).length==0){
    setLocalFilterType('Reset');
    return;
  }
  const type=model?.fundRequestStatus?.filterModels[1]?.values.join();

  switch(type?.toLowerCase()){
      case 'open':
        setLocalFilterType('Open');
        break;
      case 'pending':
        setLocalFilterType('Pending');
        break;
      case 'deleted':
        setLocalFilterType('Deleted');
        break;
      default:
        setLocalFilterType(null);
    }
}
  
const setLocalFilterType=(type: any) =>{
  localStorage.setItem('filterModelType', type);
}
const getLocalFilter=() =>{
     return JSON.parse(localStorage.getItem('filterModel') || '{}');
}
const getLocalFilterType=() =>{
  return localStorage.getItem('filterModelType') || null;
}

const setPaginationSize=(value:any)=>{
  sessionStorage.setItem('pageSize',value)
}
const getPaginationSize=()=>{
  return sessionStorage.getItem('pageSize')
}

const getDateString=(date:string)=>{
  let formattedDateString=''
  if(date && date.split("").includes('T')){
    return moment(date).valueOf()
  }
    const first=date?.split('/')[0];
    const second=date?.split('/')[1];
    const third=date?.split('/')[2];
    formattedDateString=`${third}${second}${first}`

  return formattedDateString
}

const dateSortingComparator=(date1:any, date2:any)=>{
  if(date1 ===null || date2===null){
    return date1 === null ? -1 : 1
  }
  date1=getDateString(date1)
  date2=getDateString(date2)
  return Number(date1)-Number(date2)
}

export {
  dateComparator,
  dateComparatorWithTime,
  getdefaultFilters,
  setLocalFilter,
  getLocalFilter,
  setLocalFilterType,
  getLocalFilterType,
  setPaginationSize,
  getPaginationSize,
  dateSortingComparator
}
