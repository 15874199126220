import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationRoute } from '../models/navigation.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private router: Router;
  private route: ActivatedRoute;
  private location: Location;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.location = injector.get(Location);
  }

  public navigateTo(request: NavigationRoute): void {
    this.router.navigate(
      request.commands,
      request.absolute ? undefined : { relativeTo: this.route }
    );
  }

  public navigateWithQueryParam(
    request: NavigationRoute,
    queryParams: any
  ): void {
    this.router.navigate(request.commands, { queryParams });
  }
  
  public navigateForward(): void {
    this.location.forward();
  }

  public navigateBack(): void {
    this.location.back();
  }
  navigateToSelf() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    const self = ".";
    this.router.navigate([self]);
  }  

  get currentRoute(): NavigationRoute {
    return new NavigationRoute(this.route.snapshot.url, true);
  }

  get createFundRequestRoute(): NavigationRoute {
    return new NavigationRoute(['dashboard', 'create-fund-request'], true);
  }

  get getFundListingRoute(): NavigationRoute {
    return new NavigationRoute(['dashboard', 'fund-listing'], true);
  }

  get getHomePageUrl(): NavigationRoute {
    return new NavigationRoute(['home'], true);
  }

  get getDtFundListingRoute(): NavigationRoute {
    return new NavigationRoute(['dt-dashboard'], true);
  }

  get getLiveMappingRoute(): NavigationRoute {
    return new NavigationRoute(['custom-mapping'], true);
  }

  public getAssignFRList(id: any): NavigationRoute {
    return new NavigationRoute(['administration', 'fr', id], true);
  }

  public getAssignFRDetails(
    id: any,
    name: any,
    tpaName: any,
    aggConsoleFlag: string,
    parentChild: string,
    isSelfOnBoarded?:boolean ,
    connectedAudit="No",
    workpaperType?:string
  ): NavigationRoute {
    return new NavigationRoute(
      ['dt-dashboard', id, name, tpaName, aggConsoleFlag, parentChild,isSelfOnBoarded,connectedAudit,workpaperType],
      true
    );
      }

  public getManageNotification(): NavigationRoute {
    return new NavigationRoute(['manage-notification'], true);
  }

  public getAllNotification(): NavigationRoute {
    return new NavigationRoute(['all-notification'], true);
  }

  public getAuditorDashboard(): NavigationRoute {
    return new NavigationRoute(['auditor-dashboard'], true);
  }

  public getAuditorFundDetails(
    id: any,
    name: any,
    tpaName: string,
    aggConsoleFlag : string,
    isSelfOnBoarded:boolean=false,
    workpaperType?:string
  ): NavigationRoute {
    return new NavigationRoute(['auditor-dashboard', id, name, tpaName, aggConsoleFlag,isSelfOnBoarded,workpaperType], true);
  }

  public getUserDetailsRoute(userId: any): NavigationRoute {
    return new NavigationRoute(
      ['user-access-management', 'user', userId],
      true
    );
  }

  public getTeamDetailsRoute(teamId: any): NavigationRoute {
    return new NavigationRoute(
      ['user-access-management', 'team', teamId],
      true
    );
  }

  public getLoginUrl(): NavigationRoute {
    return new NavigationRoute(['login'], true);
  }
}
