<div *ngIf="showModal" class="modal-container" [ngStyle]="{ top: top + 'px' }">
  <div
    class="modal-body"
    [ngStyle]="{
      top: modalBodyTopPosition ? modalBodyTopPosition + 'px' : '',
      right: modalBodyRightPosition ? modalBodyRightPosition + 'px' : '',
      width: modalBodyWidth ? modalBodyWidth + 'px' : ''
    }"
  >
    <ng-content></ng-content>
  </div>
  <a *ngIf="showModal" (click)="closeModalPopUp()" class="modal-bg-overlay"></a>
</div>
