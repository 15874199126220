<div class="motif-row">
  <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
    <p class="page-title">{{ "Self-Onboarding" }}</p>
    <span class="motif-subtitle page-subtitle">{{
      "To add new administrator to WAMapps, follow the two steps outlined below"
    }}</span>
  </div>
</div>
<div class="self-onboarding-description-container">
  <motif-card
    >{{
      "Are you planning to self-onboard your own client data onto WAMapps? Our team is available to help with questions and guidance."
    }}<br />{{ "Reach out to the team at" }}
    <a href="mailto:info@wamapps@ie.ey.com">{{ "info@wamapps@ie.ey.com" }}</a
    >{{ " before you get started." }}
  </motif-card>
</div>
<div class="self-onboarding-container">
  <motif-card>
    <motif-progress-indicator>
      <motif-stepper
        title="Step 01"
        subtitle="Add administrator"
        class="clickable"
        tabindex="0"
        [status]="getStatus"
      ></motif-stepper>
    </motif-progress-indicator>
    <section>
      <span class="motif-h2 title">{{ "Add Administrator(TPA) name" }}</span>
      <span class="subtitle">{{
        "Enter unique Administrator(TPA) name or select existing Administrator from dropdown and submit"
      }}</span>
      <div class="display-column-container">
        <div>
          <motif-form-field>
            <label motifLabel>{{ "Enter new Administrator name" }}</label>
            <input
              [(ngModel)]="tpaName"
              (keypress)="selectedTpa = ''"
              motifInput
              type="text"
              aria-label="Enter new Administrator name"
            />
            <!-- <motif-error>{{ "Please type valid tpa name" }} </motif-error> -->
          </motif-form-field>
          <motif-error *ngIf="getError"
            >{{ "Administrator name already exists" }}
          </motif-error>
        </div>
        <span> Or </span>
        <motif-form-field class="margin-top">
          <label motifLabel>{{ "Select existing Administrator" }}</label>
          <motif-select
            aria-label="Aria Label"
            [filter]="true"
            (selectionChange)="isTpaSubmitted = false; tpaName = ''"
            [(ngModel)]="selectedTpa"
            aria-label="role"
          >
            <motif-option *ngFor="let tpa of tpaList" [value]="tpa.tpaId">{{
              tpa.tpaName
            }}</motif-option>
          </motif-select>
        </motif-form-field>
        <button
          (click)="submitTpa()"
          [disabled]="(!tpaName && !selectedTpa) || getError || isTpaSubmitted"
          class="submit-btn"
          motifButton
          color="primary-alt"
          aria-label="Add"
          role="button"
        >
          {{ "Submit" }}
        </button>
      </div>
    </section>
  </motif-card>
  <motif-card class="step-two-container">
    <motif-progress-indicator>
      <motif-stepper
        title="Step 02"
        subtitle="Upload the Administrator(TPA) files"
        class="clickable"
        tabindex="0"
        [status]="getStatus"
      ></motif-stepper>
    </motif-progress-indicator>
    <section>
      <span class="motif-h2 title">{{
        "Mapping & Administrator(TPA) files"
      }}</span>
      <span class="subtitle step-two-subtitle">{{
        "Refer guide & training videos before creating the mapping files or uploading Administrator files through SFTP."
      }}</span>
      <motif-chip-group class="chip-group">
        <motif-chip type="chip">{{ "User guide" }}</motif-chip>
        <motif-chip type="chip">{{ "Trainings" }}</motif-chip>
      </motif-chip-group>
      <span
        class="link-tag"
        (click)="downloadFiles(downloadFileOptions.downloadSampleFile)"
        >{{ "Download sample files "
        }}<img
          class="download-icon"
          alt="Download Sample Files"
          role="button"
          src="/assets/images/downloadAction.png"
      /></span>
      <span
        class="link-tag"
        (click)="downloadFiles(downloadFileOptions.downloadDeleveryMemo)"
        >{{ "Self-Onboarding Delivery Memo - To be completed by audit team "
        }}<img
          class="download-icon"
          alt="download workpaper"
          role="button"
          src="/assets/images/downloadAction.png"
      /></span>
      <button
        class="submit-btn"
        motifButton
        size="large"
        color="primary-alt"
        aria-label="Add"
        [disabled]="!isTpaSubmitted"
        role="button"
        [title]="'Complete Step 1 \nbefore uploading the files'"
        (click)="openEYDFPortal()"
      >
        {{ "Create & Upload" }}
      </button>
    </section>
  </motif-card>
</div>
