import { Injectable } from '@angular/core';
import { AppConst } from '@app/App.const';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './helpers';
import { SettingsService } from './settings.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class OAuthServiceService {
  constructor(
    private oauthService: OAuthService,
    private settinService: SettingsService
  ) {
    if (this.settinService.isUserLoggedin() === false) {
      // this condition is added to avoid overwrite access token on /login
      this.oauthService.configure(authConfig);
      this.oauthService.setupAutomaticSilentRefresh();
      this.oauthService.tryLogin({});

      if (this.oauthService.getAccessToken()) {
        this.getAccessToken();
      }
    }
  }

  public login() {
    this.oauthService.initImplicitFlow();
  }

  public getAccessToken() {
    const sessionId = sessionStorage.getItem(AppConst.SESSION_ID_KEY);
    if (!sessionId) {
      sessionStorage.setItem(AppConst.SESSION_ID_KEY, uuidv4());
    }
    this.settinService.setToken(this.oauthService.getAccessToken());
    // this.getPermissionsByModule();
  }

  public refreshToken() {
    this.oauthService
      .silentRefresh()
      .then((info) => {
        console.log('refresh ok', info);
        if (this.oauthService.getAccessToken()) {
          this.getAccessToken();
        }
      })
      .catch((err) => console.log('refresh error', err));
  }

  public extentToken() {
    this.oauthService
      .silentRefresh()
      .then((info) => {
        if (this.oauthService.getAccessToken()) {
          this.getExtendedAccessToken();
        }
      })
      .catch((err) => console.log('refresh error', err));
  }

  public getExtendedAccessToken() {
    this.settinService.setToken(this.oauthService.getAccessToken());
  }
}
