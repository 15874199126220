import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './common/components/modal/modal.component';
import { TableComponent } from './common/components/table/table.component';
import { FiltersComponent } from './common/components/filters/filters.component';
import { TabFilterComponent } from './common/components/filters/tab-filter/tab-filter.component';
import { MotifModule } from '@app/motif.module';
import { SearchComponent } from './common/components/filters/search/search.component';
import { CellRendererComponent } from './common/components/table/cell-renderer/cell-renderer.component';
import { ToasterComponent } from './common/components/toaster/toaster.component';
import { CustomTooltipComponent } from './common/components/table/custom-tooltip/custom-tooltip.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './common/components/breadcrumb/breadcrumb.component';
import { CustomEllipsisPipe } from './common/pipes/custom-ellipsis.pipe';
import { StatusRendererComponent } from './common/components/table/status-renderer/status-renderer.component';
import { CustomKeyvaluePipe } from './common/pipes/keyvalue.pipe';
import { SearchFilterPipe } from './common/pipes/search-filter.pipe';
import { ManageNotificationComponent } from '@app/layout/mainframe/page/header/notification/manage-notification/manage-notification.component';
import { NewRecipientComponent } from './common/components/new-recipient/new-recipient.component';
import { FormatTextByAddingClassPipe } from './common/pipes/format-text-by-adding-class.pipe';

import { ConfirmModalComponent } from './common/components/confirm-modal/confirm-modal.component';
import { ShowDisableDirective } from './common/directives/show-disable.directive';
import { ShowHideDirective } from './common/directives/show-hide.directive';
import { CustomDateComponent } from './common/components/custom-date/custom-date.component';

@NgModule({
  declarations: [
    ModalComponent,
    TableComponent,
    FiltersComponent,
    TabFilterComponent,
    SearchComponent,
    CellRendererComponent,
    ToasterComponent,
    CustomTooltipComponent,
    BreadcrumbComponent,
    CustomEllipsisPipe,
    StatusRendererComponent,
    CustomKeyvaluePipe,
    SearchFilterPipe,
    ManageNotificationComponent,
    NewRecipientComponent,
    FormatTextByAddingClassPipe,
    ShowDisableDirective,
    ConfirmModalComponent,
    ShowHideDirective,
    CustomDateComponent,
  ],
  imports: [
    CommonModule,
    MotifModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ModalComponent,
    TableComponent,
    FiltersComponent,
    TabFilterComponent,
    SearchComponent,
    CellRendererComponent,
    ToasterComponent,
    CustomTooltipComponent,
    BreadcrumbComponent,
    CustomEllipsisPipe,
    CustomKeyvaluePipe,
    SearchFilterPipe,
    ManageNotificationComponent,
    NewRecipientComponent,
    FormatTextByAddingClassPipe,
    ShowDisableDirective,
    ShowHideDirective,
    AgGridModule
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
