// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//   awCZnURV9WEzWgSwEH4qEEsGlUdalx18
//   xApiKey: '',
export const environment:{
    production: boolean,
    baseAPIEndpoint: string,
    xApiKey :string | null,
    currentEnv:string
  } = {
    production: true,
    baseAPIEndpoint: 'https://wamapps-fa3-dev02.sbp.eyclienthub.com',
    xApiKey: null,
    currentEnv:'dev'
  };
  
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  