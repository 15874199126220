import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private toasterConfig$ = new Subject();
  public toasterConfigSub$ = this.toasterConfig$.asObservable();

  public showToaster(config: any) {
    this.toasterConfig$.next(config);
  }
  public showToasterSuccess(msg:string) {
    this.toasterConfig$.next([{
      type: 'success',
      show: true,
      message:msg
    }]);
  }
  public showToasterError(msg: string) {
    this.toasterConfig$.next([{
      type: 'error',
      show: true,
      message:msg
    }]);
  }
  public showToasterWarning(msg: string) {
    this.toasterConfig$.next([{
      type: 'warning',
      show: true,
      message:msg
    }]);
  }
  }
