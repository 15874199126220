<!-- title with count -->
<div class="motif-row">
  <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
    <p class="page-title">
      <strong>{{
        "All Notifications " + " (" + notificationsBackup.length + ")"
      }}</strong>
    </p>
  </div>
</div>

<!-- container -->
<div class="notification-container">
  <!-- notification table operations-->
  <div class="motif-row">
    <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
      <div class="button-container">
        <div class="motif-col-md-2 motif-col-sm-2 motif-col-xs-2">
          <button
            motifButton
            color="secondary"
            (click)="navigateToManageNotification()"
          >
            Manage notifications
          </button>
        </div>
        <div
          class="motif-col-md-6 motif-col-sm-2 motif-col-xs-2 btn-container-right"
        >
          <!-- actions -->
          <span *ngIf="selectedRecordCount">
            <ng-container
              *ngTemplateOutlet="
                actionOperations;
                context: {
                  $implicit: { action: actionOnBulkRecords, bulkAction: true }
                }
              "
            ></ng-container
          ></span>
          <!-- categorise -->
          <motif-form-field class="btn-container-item">
            <motif-checkbox
              (change)="categorizeChecked()"
              [(ngModel)]="categorize"
              >Categorize</motif-checkbox
            >
          </motif-form-field>
          <!-- fast filters -->
          <motif-chip-group class="btn-container-item">
            Fast Filters: &nbsp;
            <motif-chip
              *ngFor="let filter of filterOptions"
              class="fast-filter-chip"
              [variant]="
                filter.isSelected ? 'monochrome-dark' : 'monochrome-light'
              "
              (click)="fastFiltersApplied(filter)"
              >{{ filter.name }}</motif-chip
            ></motif-chip-group
          >
          <!-- search operation -->
          <div class="btn-container-item search-field">
            <app-search></app-search>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- notification table -->
  <div class="motif-row">
    <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
      <div *ngIf="!categorize">
        <ng-container
          *ngTemplateOutlet="appTable; context: { $implicit: notifications }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- accordian groups -->
<div *ngIf="categorize" class="motif-row">
  <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
    <motif-accordion type="editorial" togglePosition="after">
      <motif-expansion-panel
        *ngFor="let groupType of groupOnType"
        [expanded]="defaultOpenGroup == groupType.id"
      >
        <motif-expansion-panel-header>
          <motif-panel-title>
            {{ groupType.title }}
            <span class="time-stamp-updated"
              >Updated
              {{ groupType.updatedBefore ? groupType.updatedBefore : "NA" }}
              ago</span
            ></motif-panel-title
          >
        </motif-expansion-panel-header>
        <ng-container
          *ngTemplateOutlet="
            appTable;
            context: { $implicit: groupType.notificationsByGroupType }
          "
        >
        </ng-container>
      </motif-expansion-panel>
    </motif-accordion>
  </div>
</div>

<!-- shared table -->
<ng-template #appTable let-notifications>
  <div class="table-container">
    <app-table
      [columnDefs]="columnDef"
      [rowData]="notifications"
      [paginationRequired]="false"
      [suppressRowClickSelection]="true"
      [isRowSelectable]="undefined"
      [defaultColDef]="defaultColDef"
      (selectionChanged)="rowSelectionChanged($event)"
      (cellClicked)="onCellClicked($event)"
      [rowClassRules]="rowClassRules"
    ></app-table>
  </div>
</ng-template>

<!-- description renderer -->
<ng-template #descriptionData let-params>
  <div class="description-data-wrap">
    {{ params.description }}
  </div>
</ng-template>

<!-- action rendere -->
<ng-template #actionOperations let-params>
  <div class="action-operations">
    <!-- seen -->
    <button
      motifIconButton
      (click)="performAction('seen', params.messageUuid, params?.bulkAction)"
      class="seen-action-icon"
    >
      <motif-icon
        [title]="
          params?.seen
            ? 'Seen'
            : params?.seen === null
            ? 'Mark selected as seen.'
            : 'Not seen'
        "
        [ngClass]="{
          'seen-seen-icon': params?.seen,
          'seen-unseen-icon': !params?.seen
        }"
        [src]="'/assets/icons/image/ic_remove_red_eye_24px.svg'"
      ></motif-icon>
    </button>
    <!-- completed -->
    <button
      motifIconButton
      [disabled]="params?.completed && params?.bulkAction"
      (click)="performAction('completed', params.messageUuid)"
    >
      <motif-icon
        [title]="
          !params?.completed ? 'Mark as task completed' : 'Marked as completed'
        "
        class="check-icon"
        [ngClass]="{
          'check-icon': !params?.completed,
          'check-icon-completed': params?.completed
        }"
        [src]="'/assets/icons/action/ic_check_circle_24px.svg'"
      ></motif-icon>
    </button>
    <!-- flag -->
    <button
      motifIconButton
      (click)="performAction('flag', params.messageUuid)"
      [disabled]="params?.flag && params?.bulkAction"
    >
      <motif-icon
        [title]="params?.flag ? 'Marked Flagged' : 'Mark as Flagged.'"
        [ngClass]="{
          'flag-icon-marked': params?.flag
        }"
        [src]="'/assets/icons/content/ic_flag_24px.svg'"
      ></motif-icon>
    </button>
    <button motifIconButton>
      <motif-icon
        title="Delete"
        (click)="performAction('delete', params.messageUuid)"
        class="delete-icon"
        [src]="'/assets/icons/action/ic_delete_24px.svg'"
      ></motif-icon>
    </button>
  </div>
</ng-template>

<!-- client Name With Avtar rendere -->
<ng-template #clienNameWithAvatar let-params
  ><div class="client-name">
    <motif-avatar
      [ngClass]="{
        'group-type-avatar': !params.error,
        'group-type-avatar-error': params.error
      }"
      [userName]="params.groupType"
      [size]="'small'"
    ></motif-avatar
    >&nbsp;<span [title]="params.clientName">{{ params.clientName }}</span>
  </div>
</ng-template>
