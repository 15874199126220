import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { SharedModule } from '@app/shared/shared.module';
import { MotifModule } from '@app/motif.module';
import { SessionExtendModalComponent } from './session-extend-modal/session-extend-modal.component';
import { CountdownConfig, CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [LoginComponent, SessionExtendModalComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    SharedModule,
    MotifModule,
    CountdownModule,
  ],
  providers: [
    { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
  ],
})
export class LoginModule {}

function countdownConfigFactory(): CountdownConfig {
  return { format: `mm:ss` };
}
