import { Injectable } from '@angular/core';
import { AppConst } from '@app/App.const';
import { authConfig } from './helpers';
import { OAuthService } from 'angular-oauth2-oidc';
import * as CryptoJS from 'crypto-js';
import { environment } from './../../../../../environments/environment';
import { NavigationService } from '../../navigation/navigation.service';
import { Subscription, timer } from 'rxjs';
import { MotifModal } from '@ey-xd/ng-motif';
import { SessionExtendModalComponent } from '@app/login/session-extend-modal/session-extend-modal.component';
import { ApiHttpService } from '../api-http.service';
import { ApiEndpointsService } from '../api-endpoint.service';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { ModuleLevelPermissionService } from '../Permission/module-level-permission.service';
import { encryptData } from '../../utils/app-utils';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public authdetails: any;
  public redirectUri: any;
  public refreshUri: any;
  public contextPath: any;
  public countDown: Subscription | any;
  public showErrorModel: boolean = false;
  public counter: number = AppConst.SESSION_TIMEOUT_TIME;
  public tick: number = 1000;
  public inActivityTime: any;
  public timeoutId: any;
  private userName:string='Admin'

  constructor(
    private oauthService: OAuthService,
    private navigationServie: NavigationService,
    private modal: MotifModal,
    private Api: ApiHttpService,
    private apiEndPoint: ApiEndpointsService,
    private permissionService: ModuleLevelPermissionService
  ) {}

  //added for maintaining inactivity
  public checkUserActivity(event: any) {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }

  // AUTHTOKEN FUNCTIONS
  public setToken = (value: any) => {
    const key = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_IV);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    sessionStorage.setItem(AppConst.SESSION_ACCESS_TOKEN, encrypted.toString());
  };

  public getToken = () => {
    const getDecryptedText = sessionStorage.getItem(
      AppConst.SESSION_ACCESS_TOKEN
    );
    const key = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_IV);
    if (getDecryptedText != null) {
      let decrypted = CryptoJS.AES.decrypt(getDecryptedText, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
    return null;
  };

  public getUserName(){
    return this.userName
  }
  public setUserName(name:string){
    this.userName=name
  }
  public isUserLoggedin = () => {
    const accessToken = sessionStorage.getItem('access_token');
    if (accessToken != null) {
      return true;
    } else {
      return false;
    }
  };

  public logoff() {
    this.oauthService.logOut();
    sessionStorage.removeItem('currentUserSession');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('permissionList');
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('adminPermissionList');
    sessionStorage.removeItem('pendingDownloadsBulk');
    sessionStorage.removeItem('inActivityTime');
    sessionStorage.removeItem('dailyMonthlyStatus');
    sessionStorage.removeItem('selectedDate');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('currentContext');
    sessionStorage.removeItem(AppConst.ALL_MODULE_AND_CONTROLS);
    sessionStorage.removeItem(AppConst.SELECTED_MODULE_AND_CONTROLS);
    sessionStorage.removeItem(AppConst.NUMBER_OF_TIMES_TOKEN_REFRESHED);
    sessionStorage.removeItem(AppConst.SESSION_ID_KEY);
  }

  public get name() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims;
  }

  loadAuthDetails() {
    return new Promise((resolve, reject) => {
      this.Api.callApi(
        AppConst.HttpMethods.POST,
        this.apiEndPoint.getLoginDetails(),
        null
      ).subscribe((res: any) => {
        //set the authdetails to authconfig to initialize the implict login
        sessionStorage.setItem(AppConst.NUMBER_OF_TIMES_TOKEN_REFRESHED, '0');
        this.authdetails = res;
        this.authdetails?.data?.sessionTimeout
          ? sessionStorage.setItem(
              'inActivityTime',
              this.authdetails?.data?.sessionTimeout
            )
          : sessionStorage.setItem('inActivityTime', '1800000');
        if (!this.isUserLoggedin()) {
          this.authdetails?.data?.sessionTimeout
            ? sessionStorage.setItem(
                'sessionTimeOut',
                this.authdetails.data.sessionTimeout
              )
            : sessionStorage.setItem(
                'sessionTimeOut',
                AppConst.SESSION_TIMEOUT_TIME.toString()
              );
        }
        this.redirectUri =
          location.protocol +
          '//' +
          location.host +
          location.pathname +
          'assets/redirect.html';

        this.refreshUri =
          location.protocol +
          '//' +
          location.host +
          location.pathname +
          'assets/silent-refresh.html';

        authConfig.loginUrl = this.authdetails?.data?.authenticationUrl;
        authConfig.logoutUrl = this.authdetails?.data?.logoutUrl;
        authConfig.postLogoutRedirectUri =
          location.protocol + '//' + location.host + location.pathname;
        authConfig.redirectUri = this.redirectUri;
        authConfig.clientId = this.authdetails?.data?.clientId;
        authConfig.silentRefreshRedirectUri = this.refreshUri;
        authConfig.resource = this.authdetails?.data?.resource;
        authConfig.timeoutFactor = environment.production
          ? this.authdetails?.data?.timeoutFactor
          : 0.25;
        authConfig.silentRefreshTimeout = environment.production
          ? this.authdetails?.data?.silentRefreshTimeout
          : 5000;
        this.oauthService.configure(authConfig);
        this.oauthService.loadDiscoveryDocument();
        resolve(true);
      });
    });
  }

  public extentToken() {
    this.oauthService.oidc = true;
    this.oauthService
      .silentRefresh()
      .then((info) => {
        if (this.oauthService.getAccessToken()) {
          this.getExtendedAccessToken();
        }
      })
      .catch((err) => console.log('refresh error', err));
  }

  public getExtendedAccessToken() {
    this.setToken(this.oauthService.getAccessToken());
  }

  // session expire
  public sessionTimeOut() {
    if (this.isUserLoggedin()) {
      if (JSON.parse(sessionStorage.getItem('sessionTimeOut') as any)) {
        this.counter =
          JSON.parse(sessionStorage.getItem('sessionTimeOut') as any) / 1000;
      }
      this.countDown = timer(0, this.tick).subscribe(() => {
        if (this.isUserLoggedin()) {
          if (this.counter == 0) {
            let refreshTokenCount: any = sessionStorage.getItem(
              AppConst.NUMBER_OF_TIMES_TOKEN_REFRESHED
            );
            if (+refreshTokenCount === 0) {
              this.performActionOnErrorModalClose({ button: 'Extend session' });
            } else {
              this.openErrorDialog();
              this.countDown.unsubscribe();
            }
            refreshTokenCount = +refreshTokenCount + 1; // incrementing by one
            sessionStorage.setItem(
              AppConst.NUMBER_OF_TIMES_TOKEN_REFRESHED,
              refreshTokenCount?.toString()
            );
            return;
          } else {
            --this.counter;
            let sessionCounter = this.counter * 1000;
            sessionStorage.setItem('sessionTimeOut', sessionCounter.toString());
          }
        }
      });
    }
  }

  public openErrorDialog() {
    if (this.showErrorModel) {
      return;
    }
    this.showErrorModel = true;
    const modalRef = this.modal.open(SessionExtendModalComponent, {
      id: 'session-extend-modal',
      width: '750px',
    });
    modalRef.afterClosed().subscribe((result: any) => {
      this.showErrorModel = false;
      this.performActionOnErrorModalClose(result);
      // sessionStorage.setItem(
      //   'sessionTimeOut',
      //   sessionStorage.getItem('inActivityTime') as any
      // );
    });
  }

  public performActionOnErrorModalClose(result: any) {
    if (result?.button == 'Extend session') {
      this.countDown.unsubscribe(); // added to make the timer decrement by 1000ms only even after refresh token
      //added to set sessionTimeout on refresh token
      sessionStorage.setItem(
        'sessionTimeOut',
        sessionStorage.getItem('inActivityTime') as any
      );
      this.extentToken();
    } else if (result?.button == 'Log out') {
      this.logoff();
    } else if (result?.button == 'Time out') {
      sessionStorage.setItem('navigateToLogin', 'true');
      this.logoff();
    } else {
      this.logoff();
    }
    this.counter =
      JSON.parse(sessionStorage.getItem('sessionTimeOut') as any) / 1000;

    this.sessionTimeOut();
  }

  public checkTimeOut() {
    this.inActivityTime = sessionStorage.getItem('inActivityTime');
    this.timeoutId = setTimeout(() => {
      if (this.isUserLoggedin()) {
        this.openErrorDialog();
      }
    }, this.inActivityTime);
  }

  public clearSessionTimer() {
    this.countDown = null;
    let sessionCounter = this.counter * 1000;
    sessionStorage.setItem('sessionTimeOut', sessionCounter.toString());
  }

  public getPermissionsByModule() {
    this.permissionService
      .getModuleLevelPermission()
      .subscribe((res: { moduleName: string; featureCodes: any[] } | any) => {
        if (
          res &&
          (res?.length || res?.length === 0) &&
          res?.error === undefined &&
          res?.type != 'error'
        ) {
          if (res && Object.keys(res)?.length === 0) {
            this.openErrorModal(
              [
                'Access Denied',
                'User does not have access to any module. Please contact an administrator.',
              ],
              [
                {
                  name: 'Log Out',
                  returnValue: { logout: true },
                  type: 'primary-alt',
                },
              ],
              true
            );
          } else {
            //temp added to show self onboarding for current sprint
            //TODO: This needs to come from Backend, need to fix this
            res.push({ moduleName: 'Self Onboarding', featureCodes: [] });
            res.push({ moduleName: 'Notifications', featureCodes: [] });
            res.push({ moduleName: 'ManageNotifications', featureCodes: [] });
            res.push({ moduleName:'Home', featureCodes:[]})

            this.permissionService.invokeModulePermissionDetails(res);
            sessionStorage.setItem(
              AppConst.ALL_MODULE_AND_CONTROLS,
              encryptData(JSON.stringify(res)).toString()
            );
            if (
              !sessionStorage.getItem(AppConst.SELECTED_MODULE_AND_CONTROLS)
            ) {
              this.navigationServie.navigateTo(
                this.navigationServie.getHomePageUrl
              );
            }
          }
        } else {
          this.openErrorModal(
            [
              res?.error?.message
                ? res.error.message
                : 'Something went wrong while fetching permissions.',
            ],
            [
              { name: 'Retry', returnValue: 'retry', type: 'primary-alt' },
              {
                name: 'Log Out',
                returnValue: { logout: true },
                type: 'secondary',
              },
            ],
            true
          );
        }
      });
  }

  public openErrorModal(
    messages: string[],
    buttons: any,
    actionButtonRequired: boolean = false
  ) {
    const modal = this.modal.open(ConfirmModalComponent, {
      id: 'confirm-dialog-modal-shared',
      data: {
        message: messages,
        buttons: buttons,
        actionButtonRequired: actionButtonRequired,
        title: 'Information',
      },
    });
    modal.afterClosed().subscribe((data) => {
      if (data === 'retry') {
        this.getPermissionsByModule();
      } else {
        this.logoff();
      }
    });
  }
}
