<section class="table-container" 
[ngStyle]="{ height:
      tableViewFullSizeEnabled
      ? '100vh'
      : '100%'
  }"
>
  <div class="first-heading">
    <ng-content></ng-content>
    <div *ngIf="pageSizeSelection" class="page-size-contaner">
        <label for="page-size" class="page-size-label">Page Size: </label>
        <select id="page-size" class="page-size-selector" [value]="paginationPageSize"  (change)="changePageSize($event)">
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>       
    </div>
  </div>
  <div [hidden]="!showTable" class="ag-grid-table-container">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      [modules]="modules"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [isRowSelectable]="isRowSelectable"
      [components]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [groupDisplayType]="groupDisplayType"
      [rowGroupPanelShow]="rowGroupPanelShow"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [enableRangeSelection]="enableRangeSelection"
      [groupRowRendererParams]="groupRowRendererParams"
      [pagination]="paginationRequired"
      [paginationPageSize]="paginationPageSize"
      [masterDetail]="masterDetail"
      [detailCellRendererParams]="detailCellRendererParams"
      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (rowClicked)="onRowClicked($event)"
      (columnResized)="onColumnResized($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (cellEditingStarted)="editCell($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      (paginationChanged)="onPaginationChanged($event)"
      (rowGroupOpened)="onRowGroupOpened($event)"
      (filterChanged)="filterChanged($event)"
      [rowClassRules]="rowClassRules"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [overlayLoadingTemplate]="overlayLoadingTemplate"
    >
    </ag-grid-angular>
  </div>
</section>
