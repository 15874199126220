import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  public toasterConfigs: any;
  private unsubscribe: Subject<void> = new Subject();
  
  constructor(private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.toasterService.toasterConfigSub$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((data:any) => {
      if (data?.length>=0) {
          this.toasterConfigs = data;
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete(); 
  }

  logEvent(){
    this.toasterService.showToaster({});
  }

}
