<div class="container" *ngIf="!isLoogedInUser">
  <motif-card>
    <img
      src="/assets/images/ey-logo-new.png"
      alt="logo"
      height="150"
      width="125"
    />
    <div class="card_container">
      <p>Login to your EY account</p>
      <button
        motifButton
        class="btn_client"
        (click)="routeToHome()"
        color="primary-alt"
        size="fluid"
      >
        Login for Client
      </button>
      <button
        motifButton
        color="secondary"
        (click)="routeToHome()"
        size="fluid"
      >
        Login for EY
      </button>
    </div>
  </motif-card>
</div>
<motif-toast
  [type]="'success'"
  [show]="showToasterIfUserSessionExtent"
  (close)="showToasterIfUserSessionExtent = !showToasterIfUserSessionExtent"
>
  This session has expired. Please login to continue working.
</motif-toast>
