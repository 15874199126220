import { EventEmitter, Injectable, Output } from '@angular/core';
import { AppConst } from '@app/App.const';
import { ApiEndpointsService } from '../api-endpoint.service';
import { ApiHttpService } from '../api-http.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleLevelPermissionService {
  public moduleLevelPermisssionDetails = new EventEmitter<any>();

  constructor(
    private api: ApiHttpService,
    private apiEndpoint: ApiEndpointsService
  ) {}

  public getModuleLevelPermission() {
    return this.api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndpoint.getModuleLevelPermission(),
      null
    );
  }

  public invokeModulePermissionDetails(data: any) {
    this.moduleLevelPermisssionDetails.emit(data);
  }
}
