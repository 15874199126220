// const modal = this.modal.open(ConfirmModalComponent, {
//   id: 'confirm-dialog-modal-shared',
//   data: {
//     message: [
//       string1,
//       string2,
//     ],
//     buttons : [{name : string, returnValue : any, type : 'primary-alt' | 'primary' | 'secondary'}]
//     title: string,
//   },
// });

import { Component, OnInit } from '@angular/core';
import { MotifModal } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  public title: any;
  public messages: string[] = [];
  public buttons: {
    name: string;
    returnValue: any;
    type: 'primary-alt' | 'primary' | 'secondary';
  }[] = [];
  public isActionButtonsRequired: boolean = true;
  constructor(private modal: MotifModal) {}

  ngOnInit(): void {
    const modal: any = this.modal.getModalById('confirm-dialog-modal-shared');
    this.title = modal?._containerInstance?._config?.data?.title;
    this.messages = modal?._containerInstance?._config?.data?.message;
    this.buttons = modal?._containerInstance?._config?.data?.buttons;
    this.isActionButtonsRequired =
      typeof modal?._containerInstance?._config?.data?.actionButtonRequired ==
      'boolean'
        ? modal?._containerInstance?._config?.data?.actionButtonRequired
        : true;
  }
}
