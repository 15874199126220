import { NgModule } from '@angular/core';
import {
  MotifButtonModule,
  MotifHeaderModule,
  MotifIconModule,
  MotifProgressIndicatorModule,
  MotifVerticalNavigationModule,
  MotifProgressLoaderModule,
  MotifCardModule,
  MotifBadgeModule,
  MotifDropdownModule,
  MotifToastModule,
  MotifFormsModule,
  MotifTabNavigationModule,
  MotifChipModule,
  MotifAvatarModule,
  MotifModalModule,
  MotifFiltersModule,
  MotifAccordionModule,
  MotifPaginationModule,
  MotifBreadcrumbModule,
  MotifTooltipModule,
} from '@ey-xd/ng-motif';

@NgModule({
  imports: [
    MotifHeaderModule,
    MotifButtonModule,
    MotifIconModule,
    MotifProgressIndicatorModule,
    MotifVerticalNavigationModule,
    MotifProgressLoaderModule,
    MotifCardModule,
    MotifBadgeModule,
    MotifDropdownModule,
    MotifToastModule,
    MotifFormsModule,
    MotifTabNavigationModule,
    MotifChipModule,
    MotifAvatarModule,
    MotifModalModule,
    MotifTabNavigationModule,
    MotifFiltersModule,
    MotifAccordionModule,
    MotifPaginationModule,
    MotifTooltipModule,
  ],
  exports: [
    MotifHeaderModule,
    MotifButtonModule,
    MotifIconModule,
    MotifProgressIndicatorModule,
    MotifVerticalNavigationModule,
    MotifProgressLoaderModule,
    MotifCardModule,
    MotifBadgeModule,
    MotifDropdownModule,
    MotifToastModule,
    MotifFormsModule,
    MotifTabNavigationModule,
    MotifChipModule,
    MotifAvatarModule,
    MotifModalModule,
    MotifTabNavigationModule,
    MotifFiltersModule,
    MotifAccordionModule,
    MotifPaginationModule,
    MotifBreadcrumbModule,
    MotifTooltipModule,
  ],
})
export class MotifModule {}
