import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@app/shared/common/navigation/navigation.service';
import { SignalRConnectionService } from '@app/shared/common/services/signal-r-connection.service';
import { groupTypes } from '@app/shared/common/utils/app-enum';
import { notificationJSON } from '@app/shared/common/utils/app-interface';
import { removeSpace } from '@app/shared/common/utils/app-utils';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Output() closeModel = new EventEmitter<any>();

  constructor(
    private navigation: NavigationService,
    private router: Router,
    private SignalRConnectionService: SignalRConnectionService
  ) {
    this.SignalRConnectionService.unreadNotifications.subscribe(
      (notifications: notificationJSON[]) => {
        this.getAllNotifications(notifications);
      }
    );
  }
  public currentDateTime = new Date();
  public notifications: notificationJSON[] = [];
  public isCategorized: boolean = true;
  public isErrorIncluded: boolean = false;
  public groupOnType: {
    id: number;
    title: string;
    notificationsByGroupTypeCount: number;
  }[] = [
    {
      id: groupTypes.fundRequest,
      title: 'Fund Request',
      notificationsByGroupTypeCount: 0,
    },
    {
      id: groupTypes.dataIngestion,
      title: 'Data Ingestion',
      notificationsByGroupTypeCount: 0,
    },
    {
      id: groupTypes.workpaper,
      title: 'Workpaper',
      notificationsByGroupTypeCount: 0,
    },
    {
      id: groupTypes.error,
      title: 'Error',
      notificationsByGroupTypeCount: 0,
    },
  ];

  public getAllNotifications(notifications: notificationJSON[]) {
    this.notifications = notifications;
    if (this.isCategorized) {
      this.categorizeChecked();
    }
  }

  public categorizeChecked() {
    if (this.isCategorized) {
      this.groupOnType.forEach(
        (group: {
          id: number;
          title: string;
          notificationsByGroupTypeCount: number;
        }) => {
          switch (group.id) {
            case groupTypes.fundRequest:
              group.notificationsByGroupTypeCount = this.notifications.filter(
                (notification: notificationJSON) =>
                  (!this.isErrorIncluded || !notification.error) &&
                  removeSpace(notification.groupType)?.toUpperCase() ===
                    groupTypes[groupTypes.fundRequest].toUpperCase()
              ).length;
              break;
            case groupTypes.workpaper:
              group.notificationsByGroupTypeCount = this.notifications.filter(
                (notification: notificationJSON) =>
                  (!this.isErrorIncluded || !notification.error) &&
                  removeSpace(notification.groupType)?.toUpperCase() ===
                    groupTypes[groupTypes.workpaper].toUpperCase()
              ).length;
              break;
            case groupTypes.dataIngestion:
              group.notificationsByGroupTypeCount = this.notifications.filter(
                (notification: notificationJSON) =>
                  (!this.isErrorIncluded || !notification.error) &&
                  removeSpace(notification.groupType)?.toUpperCase() ===
                    groupTypes[groupTypes.dataIngestion].toUpperCase()
              ).length;
              break;
            case groupTypes.error:
              group.notificationsByGroupTypeCount = this.notifications.filter(
                (notification: notificationJSON) =>
                  this.isErrorIncluded && notification.error
              ).length;
              break;
          }
        }
      );
    }
  }

  public postNavigation() {
    this.showModel();
  }

  public navigateToFundDetailsPage(
    notification: notificationJSON,
    event?: any
  ) {
    if (
      removeSpace(notification.groupType)?.toUpperCase() ===
        groupTypes[groupTypes.fundRequest].toUpperCase() ||
      removeSpace(notification.groupType)?.toUpperCase() ===
        groupTypes[groupTypes.workpaper].toUpperCase()
    ) {
      this.navigation.navigateWithQueryParam(
        this.navigation.getAuditorFundDetails(
          notification.fundRequestId,
          notification.fundName,
          notification.administrator,
          notification.aggConsoleFlag
        ),
        { isParent: notification.isParent }
      );
      this.postNavigation();
      event?.stopPropagation();
    }
  }

  public navigateToAllNotifications(openAction: number | null = null) {
    switch (openAction) {
      case null:
        this.navigation.navigateTo(this.navigation.getAllNotification());
        this.postNavigation();
        break;
      case groupTypes.fundRequest:
        this.router.navigate(['./all-notification'], {
          queryParams: { groupType: groupTypes.fundRequest },
        });
        this.postNavigation();
        break;
      case groupTypes.workpaper:
        this.router.navigate(['./all-notification'], {
          queryParams: { groupType: groupTypes.workpaper },
        });
        this.postNavigation();
        break;
      case groupTypes.dataIngestion:
        this.router.navigate(['./all-notification'], {
          queryParams: { groupType: groupTypes.dataIngestion },
        });
        this.postNavigation();
        break;
      case groupTypes.error:
        this.router.navigate(['./all-notification'], {
          queryParams: { groupType: groupTypes.error },
        });
        this.postNavigation();
        break;
    }
  }

  public showModel(show: boolean = false) {
    this.closeModel.emit(show);
  }

  public get groupTypes() {
    return groupTypes;
  }

  public removeSpace(inp: string) {
    return removeSpace(inp);
  }
}
