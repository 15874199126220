import { UrlSegment } from "@angular/router";

export type NavigationRouteSegment = string | number | UrlSegment;

export class NavigationRoute {
    constructor(
        public commands: NavigationRouteSegment[] = [],
        public absolute = true
    ){
        if (
            this.absolute &&
            this.commands &&
            this.commands.length > 0 &&
            typeof this.commands[0] === 'string' &&
            !(<string>this.commands[0]).startsWith('/')
          ) {
            this.commands[0] = '/' + this.commands[0];
          }
    }
}