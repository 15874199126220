import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isLoading$ = new Subject();
  public isLoading: any = this.isLoading$.asObservable();

  public showLoader() {
    this.isLoading$.next(true);
  }

  /**
   * hideLoader
   */
  public hideLoader() {
    this.isLoading$.next(false);
  }
}
