import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { SettingsService } from './shared/common/services/Authentication/settings.service';
import { CommonMethodsService } from './shared/common/services/common-methods.service';
import { SignalRConnectionService } from './shared/common/services/signal-r-connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  public count: number = 0;
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  public checkUserActivity(event: any) {
    this.settingService.checkUserActivity(event);
  }

  constructor(
    private common: CommonMethodsService,
    private SignalRConnectionService: SignalRConnectionService,
    private settingService: SettingsService
  ) {}

  ngOnInit() {
    //this.common.getConfigureConfig();
    //this.SignalRConnectionService.getAllNotifications();
    setTimeout(() => {
      this.settingService.sessionTimeOut();
    }, 100);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.settingService.isUserLoggedin()) {
        this.count++;
        if (this.count == 1) {
          this.settingService.checkTimeOut();
        }
      }
    }, 40);
  }

  ngOnDestroy(): void {
    this.settingService.clearSessionTimer();
  }
}
