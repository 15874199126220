<motif-modal-title>
  <h2 class="motif-h3">{{ title }}</h2>
  <motif-icon
    style="padding-right: 0.5rem"
    motif-modal-close
    [src]="'/assets/icons/navigation/ic_close_24px.svg'"
  ></motif-icon>
</motif-modal-title>
<motif-modal-content>
  <p *ngFor="let msg of messages">{{ msg }}</p>
</motif-modal-content>
<motif-modal-actions *ngIf="isActionButtonsRequired">
  <div *ngFor="let button of buttons" [ngSwitch]="button.type">
    <button
      *ngSwitchCase="'primary-alt'"
      motifButton
      [motif-modal-close]="button.returnValue"
      color="primary-alt"
      aria-label="Submit"
      role="button"
    >
      {{ button.name }}
    </button>
    <button
      *ngSwitchCase="'primary'"
      motifButton
      [motif-modal-close]="button.returnValue"
      color="primary"
      aria-label="Submit"
      role="button"
    >
      {{ button.name }}
    </button>
    <button
      *ngSwitchCase="'secondary'"
      motifButton
      [motif-modal-close]="button.returnValue"
      color="secondary"
      aria-label="Submit"
      role="button"
    >
      {{ button.name }}
    </button>
    <button
      *ngSwitchDefault
      motifButton
      [motif-modal-close]="button.returnValue"
      color="secondary"
      aria-label="Submit"
      role="button"
    >
      {{ button.name }}
    </button>
  </div>
</motif-modal-actions>
