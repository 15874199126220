import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommunicationService } from '@app/shared/common/services/communication.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Output() searchTextEmit: any = new EventEmitter();
  @Input() isForAgGridTable: boolean = true;
  @ViewChild('searchText') searchText!: ElementRef;
  public inputSearchValue:string=''
  constructor(
    public communicationService: CommunicationService
  ) {}
  public onFilterTextBoxChanged(text: string) {
    if (this.isForAgGridTable) {
      this.communicationService.isTableOperationsEventCalled.next({
        event: 'search',
        data: text,
      });
      this.inputSearchValue = this.searchText.nativeElement.value;
      this.communicationService.searchValueSubject.next(this.inputSearchValue);
    } else {
      this.searchTextEmit.emit(text);
    }
  }
}
