import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConst } from '@app/App.const';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  constructor(private http: HttpClient) {}

  public callApi(
    method: string,
    url: string,
    data?: any,
    options?: any
  ): Observable<any> {
    switch (method) {
      case AppConst.HttpMethods.GET:
        return this.http
          .get(url, options)
          .pipe(map(this.resonseHandler), catchError(this.errorHandler));
      case AppConst.HttpMethods.POST:
        return this.http
          .post(url, data, options)
          .pipe(map(this.resonseHandler), catchError(this.errorHandler));
      case AppConst.HttpMethods.PUT:
        return this.http
          .put(url, data, options)
          .pipe(map(this.resonseHandler), catchError(this.errorHandler));
      case AppConst.HttpMethods.DELETE:
        return this.http
          .delete(url, options)
          .pipe(map(this.resonseHandler), catchError(this.errorHandler));
      default:
        return this.http
          .get(url, options)
          .pipe(map(this.resonseHandler), catchError(this.errorHandler));
    }
  }

  private resonseHandler(res: any) {
    return res;
  }

  private errorHandler(error: any) {
    return new Observable((obs) => {
      if (typeof error.error == 'string') {
        obs.next({ error: error.error });
      } else {
        obs.next(error.error);
      }
    });
  }
}
