import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConst } from '@app/App.const';
import { OAuthService } from 'angular-oauth2-oidc';
import { NavigationService } from '@app/shared/common/navigation/navigation.service';
import { OAuthServiceService } from '@app/shared/common/services/Authentication/oauth-service.service';
import { SettingsService } from '@app/shared/common/services/Authentication/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public isLoogedInUser: boolean = false;
  // public isEycLogin: boolean = AppConst.EYC_LOGIN;
  public showToasterIfUserSessionExtent: boolean = false;

  constructor(
    private navigation: NavigationService,
    private oauthSvc: OAuthServiceService,
    private oauthService: OAuthService,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingsService
  ) {
    this.isLoogedInUser = this.settingService.isUserLoggedin();
  }

  ngOnInit(): void {
    if (
      sessionStorage.getItem('navigateToLogin') &&
      sessionStorage.getItem('navigateToLogin') !== undefined
    ) {
      this.showToasterIfUserSessionExtent = true;
      sessionStorage.removeItem('navigateToLogin');
    }
    if (this.isLoogedInUser) {
      this.navigation.navigateTo(this.navigation.getDtFundListingRoute);
    }
    // if (this.isEycLogin) {
    //   this.eycWebApplogin();
    // }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['logout'] === 'true') {
        this.isLoogedInUser = false;
      }
    });
  }

  routeToHome() {
    // if (!this.isEycLogin) {
    this.eycWebApplogin();
    // }
  }

  eycWebApplogin() {
    if (!this.oauthService.getAccessToken()) {
      this.oauthSvc.login();
    } else {
      this.navigation.navigateTo(this.navigation.getHomePageUrl);
    }
  }
}
