import { EventEmitter, Injectable, Output } from '@angular/core';
import { AppConst } from '@app/App.const';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { regionOnFeatureJSON } from '../utils/app-interface';
import { fileDownload } from '../utils/app-utils';
import { ApiEndpointsService } from './api-endpoint.service';
import { ApiHttpService } from './api-http.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class CommonMethodsService {
  public downloadWorkpaperSub$!: Subscription;
  @Output() public userDetail=new EventEmitter<any>();
  public configureConfig: any;
  private deleteRecord$ = new BehaviorSubject(false);
  public deleteRecordObservable$: any = this.deleteRecord$.asObservable();
  public _selectedRegionOnDtDashboard!: regionOnFeatureJSON;

  public getSelectedRegionOnDtDashboard() {
    if(this._selectedRegionOnDtDashboard){
      return this._selectedRegionOnDtDashboard;
    }
    if(sessionStorage.getItem(AppConst.SELECTED_REGION_ON_DT_DASHBOARD)){
      return JSON.parse(sessionStorage.getItem(AppConst.SELECTED_REGION_ON_DT_DASHBOARD) || '{}');
    }
    return false
  }

  public setSelectedRegionOnDtDashboard(val: regionOnFeatureJSON) {
    this._selectedRegionOnDtDashboard = val;
    sessionStorage.setItem( AppConst.SELECTED_REGION_ON_DT_DASHBOARD, JSON.stringify(val));
  }

  constructor(
    private Api: ApiHttpService,
    private apiEndPoint: ApiEndpointsService,
    public toasterService: ToasterService
  ) {}

  public downloadWorkpapersOnfundReqIds(
    frIds: string[],
    observableRequired: boolean = false
  ): any {
    const observable: Observable<any> = this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.downloadMultipleWorkpapers(frIds),
      null,
      { responseType: 'blob' as 'json', observe: 'response' }
    );
    if (observableRequired) {
      return observable;
    } else {
      observable.subscribe((res: any) => {
        if (res?.body && res.status == '200') {
          let blob = new Blob([res.body], { type: res?.body.type });
          let fileName = res?.headers
            ?.get('content-disposition')
            ?.split('"')[1];
          fileDownload(fileName, blob);
          this.toasterService.showToasterSuccess(`Workpapers downloaded successfully!`);
        } else {
          this.toasterService.showToasterError('Failed to download Workpapers!');
        }
      });
    }
  }

  public downloadFrTemplate(observableRequired: boolean = false): any {
    const observable: Observable<any> = this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getDownloadUrl(),
      null,
      { responseType: 'blob' as 'json', observe: 'response' }
    );
    if (observableRequired) {
      return observable;
    } else {
      observable.subscribe((response: any) => {
        //1st way
        if (response?.body && response.status == '200') {
          let blob = new Blob([response.body], { type: response?.body.type });
          let fileName = response.headers
            .get('content-disposition')
            .split('"')[1];
          fileDownload(fileName, blob);
          this.toasterService.showToasterSuccess('Fund request downloaded successfully!');
        } else {
          this.toasterService.showToasterError('Failed to download fund request!');
        }
      });
    }
  }

  public downloadSingleWorkpaper(
    frId: string,
    workpaperId: string,
    isDTDashboard: boolean,
    observableRequired: boolean = false,
    wpName?:string
  ): any {
    const observable = this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getDownloadWorkpaperUrl(
        frId,
        workpaperId,
        isDTDashboard
      ),
      null,
      { responseType: 'blob' as 'json', observe: 'response' }
    );
    if (observableRequired) {
      return observable;
    } else {
      observable.subscribe((response: any) => {
        console.log(response);
        if (response?.body && response.status == '200') {
          let blob = new Blob([response.body], { type: response?.body.type });
          let fileName = response?.headers
            ?.get('content-disposition')
            ?.split('"')[1] || `${wpName}.xlsx` || 'Workpaper.xlsx';
          fileDownload(fileName, blob);
          this.toasterService.showToasterSuccess('Workpaper downloaded successfully!');
        } else {
          this.toasterService.showToasterError('Failed to download Workpaper!');
        }
      });
    }
  }

  public downloadCalculation(
    frId: string,
    calculationId: string,
    observableRequired: boolean = false
  ): any {
    const observable = this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getDownloadCalculationUrl(frId, calculationId),
      null,
      { responseType: 'blob' as 'json', observe: 'response' }
    );
    if (observableRequired) {
      return observable;
    } else {
      observable.subscribe((response: any) => {
        if (response?.body && response.status == '200') {
          let blob = new Blob([response.body], { type: response?.body.type });
          let fileName = response.headers
            .get('content-disposition')
            .split('"')[1];
          fileDownload(fileName, blob);
          this.toasterService.showToasterSuccess('Calculation downloaded successfully!');
        } else {
          this.toasterService.showToasterError('Failed to download calculation!');
        }
      });
    }
  }

  /**
   * getConfigureConfig
   */
  public getConfigureConfig(observableRequired: boolean = false): any {
    const observable: Observable<any> = this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getFRValidationConfig(),
      null
    );
    if (observableRequired) {
      return observable;
    } else {
      observable.subscribe((data: any) => {
        if (data) {
          this.configureConfig = Object.assign([], data);
        }
      });
    }
  }

  public deleteRecord(confirm: any) {
    this.deleteRecord$.next(confirm);
  }

  public openUrlInNewTab(url: string, name: string = '_blank') {
    window.open(url, name);
  }
  public getUserDetails(observableRequired:boolean=false){
    const observable: Observable<any>=this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getUserDetailsUrl(),
      null
    )
    if(observableRequired){
      return observable
    }else{
      observable.subscribe((data) => {
        if (data?.name && data?.email) {
          this.userDetail.emit(data);
        }
      });
      return
    }
  }
  public getUserDetailsWithPersona(){
    const observable: Observable<any>=this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.apiEndPoint.getUserDetailsWithPersona(),
      null
    )
    return observable
  }

  public payLoadKeysConfig:any=new Set([])

  public setPayLoadkeys(key:any) {
   this.payLoadKeysConfig.add(key)
   
  }
  public resetPayloadKeys(){
    this.payLoadKeysConfig=new Set([])
  }
}
