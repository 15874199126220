// Angular Modules
import { Injectable } from '@angular/core';
import { AppConst } from '@app/App.const';
import { QueryStringParameters } from '../classes/query-string-parameters';
import { UrlBuilder } from '../classes/url-builder';

@Injectable({
  providedIn: 'root',
})
export class ApiEndpointsService {
  private createUrl(
    action: string,
    isSignalRConnection: boolean = false,
    isWorkpaper: boolean = false,
    isCalculationAPI: boolean = false,
    isSharepoint: boolean = false,
    isUserManagement: boolean = false,
    isAuthentication: boolean = false,
    isStorageService: boolean = false
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      isStorageService
        ? AppConst.API_STORAGE_SERVICE_ENDPOINT
        : isUserManagement
        ? AppConst.API_USER_MANAGEMENT_SERVICE_ENDPOINT
        : isSharepoint
        ? AppConst.API_SHAREPOINT_SERVICE_ENDPOINT
        : isWorkpaper
        ? AppConst.API_WORKPAPER_SERVICE_ENDPOINT
        : isSignalRConnection
        ? AppConst.SIGNALR_CONNECTION_ENDPOINT
        : isCalculationAPI
        ? AppConst.API_CALCULATION_SERVICE_ENDPOINT
        : isAuthentication
        ? AppConst.API_AUTHENTICATION_SERVICE_ENDPOINT
        : AppConst.API_FUND_REQ_ENDPOINT,
      action
    );
    return urlBuilder.toString();
  }

  private createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      AppConst.API_ENDPOINT,
      action
    );
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  private createUrlWithPathVariables(
    action: string,
    pathVariables: any[] = []
  ): string {
    let encodedPathVariablesUrl: string = '';
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(
          pathVariable.toString()
        )}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(
      AppConst.API_ENDPOINT,
      `${action}${encodedPathVariablesUrl}`
    );
    return urlBuilder.toString();
  }

  public getDownloadUrl(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/download`);
  }

  public getDownloadMemoUrl(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/memo/download`);
  }

  /**
   * getUploadUrl
   */
  public getUploadUrl() {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/upload`);
  }

  public getSignalRConnectionUrl(): string {
    return this.createUrl(`signalr/negotiate`, true);
  }

  public getUploadedFundRequestListUrl(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/uploadedFundRequests`
    );
  }

  public getFundRequestListUrl(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/fundlist`);
  }

  public testUrl() {
    this.createUrlWithQueryParameters(
      `${AppConst.VERSION_1}/fundrequest/download`
    );
    return this.createUrlWithPathVariables(
      `${AppConst.VERSION_1}/fundrequest/download`,
      [1, 2]
    );
  }

  public getFRListUrl(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/tpa/fund-requests`
    );
  }

  public getWorkpaperList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/workpaper/detail`,
      false,
      false,
      true
    );
  }

  public getTokenToDownloadReport(workpaperId: any, frId: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/pbi/access-token`,
      false,
      false,
      true
    );
  }

  public getAuditorWorkpaperList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/workpaper/detail`,
      false,
      false,
      true
    );
  }

  public uploadNewWorkpaper(workpaperName: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/upload/workpaper-template?workpaperName=${workpaperName}`,
      false,
      false,
      false,
      true
    );
  }

  public downloadWorkpaperTemplate(workpaperTemplateId: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/download/workpaper-template?workpaperTemplateId=${workpaperTemplateId}`,
      false,
      false,
      false,
      true
    );
  }

  public getWorkpaperTemplateList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/workpaper-templates-list`,
      false,
      false,
      false,
      true
    );
  }

  public renameWorkpaperTemplate(
    workpaperTemplateId: any,
    workpaperTemplateName: any
  ): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/rename/workpaper-template?workpaperTemplateId=${workpaperTemplateId}&workpaperTemplateUpdateName=${workpaperTemplateName}`,
      false,
      false,
      false,
      true
    );
  }

  public updateExistingWorkpaper(workpaperTemplateId: any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/update/workpaper-template?workpaperTemplateId=${workpaperTemplateId}`,
      false,
      false,
      false,
      true
    );
  }

  public getDownloadWorkpaperUrl(
    frId: any,
    workpaperId: any,
    isDTDashboard: boolean // added to identify the API called from Dt or Auditor dashboard
  ): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/downloadFile?fundRequestId=${frId}&workpaperId=${workpaperId}`,
      false,
      false,
      false,
      true
    );
  }
  public getDownloadInputAndCdmUrl(
    frId: any,
    id: any[],
    isCdm:boolean,
  ): string {
    const fileIdType=isCdm ? 'cdmFileId' : 'inputFileIds'
    const ids=id.join(",")
    return this.createUrl(
      `${AppConst.VERSION_1}/downloadFile?fundRequestId=${frId}&${fileIdType}=${ids}`,
      false,
      false,
      false,
      true
    );
  }
  public getDownloadCalculations(
    frId: string,
    id: string,
  ): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/downloadFile?fundRequestId=${frId}&calculationId=${id}`,
      false,
      false,
      false,
      true
    );
  }

  public getDownloadCalculationUrl(frId: any, calculationId: any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/calculation/download?fundRequestId=${frId}&calculationId=${calculationId}`,
      false,
      false,
      true
    );
  }

  public downloadMultipleWorkpapers(frId: any): string {
    const obj = Object.keys(frId);
    let finalArray: any = [];
    obj.forEach((id: any) => {
      finalArray.push(id + '=' + frId[id]);
    });
    return this.createUrl(
      `${AppConst.VERSION_1}/download/workpapers?${finalArray.join('&')}`,
      false,
      false,
      false,
      true
    );
  }

  public getCalculationList(frId: any, tpaName: string,isSelfOnBoarded:boolean | string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/calculation/details?fundRequestId=${frId}&tpaName=${tpaName}&isSelfOnBoarded=${isSelfOnBoarded}`,
      false,
      false,
      true
    );
    // return `http://wamapp-fa3-dev.sbp.eyclienthub.com/wamapps/calculation/api/v1/calculation/details/7BC4862C-508E-49C8-9683-73FF06EE1FBB`;
  }

  public getAssignListUrl(tpaName: any): string {
    
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/tpa/fund-request?tpaName=${tpaName}`
    );
  }

  public validateFundRequestsFile(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/validate-fund-requests-file`
    );
  }

  public validateFundRequests(): string {
    //return `https://demo8156404.mockable.io/validate-fund-requests`;
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/validate-fund-requests`
    );
  }

  public getCurrentFrTemplate(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/get-current-fund-request-template-columns`
    );
  }

  public updateFRTemplate(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/update-fund-request-template`
    );
  }

  public uploadFRTemplate(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/upload-fund-request-template`
    );
  }

  public uploadFRRequests(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/upload-fund-request`
    );
  }

  public createCustomMapping(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/mapping/upload`,
      false,
      false,
      true
    );
  }

  public validateCustomMappingFile(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/upload`,
      false,
      false,
      true
    );
  }

  public updateCustomMapping(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/mapping/update`,
      false,
      false,
      true
    );
  }

  public getCustomMappingList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/custommappings`,
      false,
      false,
      true
    );
  }

  public getExistingCustomMappingList(tpaId: any, mappingTypeId: any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/existing-mappings-list?tpaId=${tpaId}&mappingTypeId=${mappingTypeId}`,
      false,
      false,
      true
    );
  }

  // public downloadCustomMapping(selectedCusomMappingFileNames: any): string {
  //   return this.createUrl(
  //     `${
  //       AppConst.VERSION_1
  //     }/custom-mapping/downloads?filesName=${selectedCusomMappingFileNames.join(
  //       '&filesName='
  //     )}`,
  //     false,
  //     false,
  //     false,
  //     true
  //   );
  // }

  public downloadCustomMapping(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/mapping/bulk-download`,
      false,
      false,
      true
    );
  }
  public downloadSingleCustomMapping(customMappingId:any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/mapping/download/${customMappingId}`,
      false,
      false,
      true
    );
  }

  /**
   * downloadSingleCustomMappingFile
   */
  public downloadSingleCustomMappingFile(customMappingFileName: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/custom-mapping/download/${customMappingFileName}`,
      false,
      false,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getTPAList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/tpa`,
      false,
      false,
      true
    );
  }

  public addNewTpa(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/tpa/add`);
  }

  public deleteCustomMapping(tpaCustomMappingIds: any): string {
    return this.createUrl(
      `${
        AppConst.VERSION_1
      }/custommapping/delete?tpaCustomMappingIds=${tpaCustomMappingIds.join(
        '&tpaCustomMappingIds='
      )}`,
      false,
      false,
      true
    );
  }

  public getMappingTypeForSelectedTPA(tpaName: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/mapping-types?tpaName=${tpaName}`,
      false,
      false,
      true
    );
  }

  public getApplyMappingList(tpaId: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/apply-mappings?tpaId=${tpaId}`,
      false,
      false,
      true
    );
  }

  public applyMapping() {
    return this.createUrl(
      `${AppConst.VERSION_1}/custommapping/apply-mappings`,
      false,
      false,
      true
    );
  }

  public getAllRegionsOnFeatureGroup(featureGroup: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/regions-for-user?featureGroup=${featureGroup}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getFundList(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/fundlist`);
  }

  public getFundListStatus(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/fund-request-status`
    );
  }

  public getDataActionStatus(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/fund-request-data-action`
    );
  }

  public getFRRerunUrl(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/rerun-frs`);
  }

  public getFRReleaseUnRelaseAllUrl(isRlease: boolean): string {
    //release true , unrelease false
    return this.createUrl(
      `${AppConst.VERSION_1}/workpaper/release-unrelease?isrForRelease=${isRlease}`,
      false,
      false,
      true
    );
  }

  public getFRenableDisableExternalValuationUrl(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/dt-dashboard/external-valuation/update`
    );
  }

  public getFRdeleteUrl(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/delete`);
  }

  public getFRresetUrl(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/reset`);
  }

  public getReviewFlagChangeUrl(
    fundRequestId: string,
    workpaperId: string,
    isReviewed: boolean,
    workpaperCalculationId: string
  ): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/workpaper/getreviewstatus?fundRequestId=${fundRequestId}&workpaperId=${workpaperId}&isReviewed=${isReviewed}&workpaperCalculationId=${workpaperCalculationId}`,
      false,
      false,
      true
    );
  }

  public getUpdateStatusUrl(
    fundRequestIds: string[],
    statusTo: any,
    isUndoFlag: boolean = false
  ): string {
    return this.createUrl(
      `${
        AppConst.VERSION_1
      }/fundrequest/update-status?statusCode=${statusTo}&isUndoFlag=${isUndoFlag}&fundRequestIds=${fundRequestIds.join(
        '&fundRequestIds='
      )}`
    );
  }

  public getSummaryData(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/graph/details`
    )
  }

  public getAudiordashboardData(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/auditor/dashboard/fundlist`
    );
  }

  public getFundInformation(frId: string): string {
    const frIdLowerCase = frId?.toLowerCase(); //lower case added to fix bug -- data not getting fetched from monodb when id is uppercase
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/auditor-dashboard/fund-information?fundRequestId=${frIdLowerCase}`
    );
  }

  public getFundInfoForColumn(frId: string): string {
    const frIdLowerCase = frId?.toLowerCase(); //lower case added to fix bug -- data not getting fetched from monodb when id is uppercase
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/dt-dashboard/fund-request/${frIdLowerCase}`
    );
  }

  public getDTFundInformation(frId: string): string {
    const frIdLowerCase = frId?.toLowerCase(); //lower case added to fix bug -- data not getting fetched from monodb when id is uppercase
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/dt-dashboard/fund-information/${frIdLowerCase}`
    );
  }

  public updateFundInformation(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/auditor-dashboard/fund-information/update`
    );
  }

  public updateDTFundInformation(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/dt-dashboard/fund-information/update`
    );
  }

  public getFRValidationConfig(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/fr/template/validations`
    );
  }

  public setFRValidationConfig(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/fr/template/validations`
    );
  }

  //User management APIs

  public getTeamsList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/teams`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getBulkUserUploadUrl(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/users/upload`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getRolesList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/roles`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getUsersList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/users`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public deleteSelectedUsers() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/delete/user`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public deleteSelectedTeams() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/delete/team`,
      false,
      false,
      false,
      false,
      true
    );
  }

  /**
   * addUser
   */
  public addUser(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/user`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public updateUser(userId: any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/user/${userId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public addTeam(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/team`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public updateTeam(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/teamUpdate`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public addRole() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/role`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public editRole(roleId: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/updateRole?roleId=${roleId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public deleteSelectedRole(selectedRoleId: any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/deleteRole?roleId=${selectedRoleId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  /**
   * getPermissionsByRoleId
   */
  public getPermissionsByRoleId(roleId: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/features?roleId=${roleId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  /**
   * updatePermissionsForRole
   */
  public updatePermissionsForRole() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/roleFeatures`,
      false,
      false,
      false,
      false,
      true
    );
  }

  /**
   * removeUsersFromTeam
   */
  public removeUsersFromTeam() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/removeteamMember`,
      false,
      false,
      false,
      false,
      true
    );
  }

  /**
   * addMembersToTeam
   */
  public addMembersToTeam() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/teamMember`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getTeamDetails(teamId: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/team/get-team-by-id?teamId=${teamId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getUsersByTeamId(teamId: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/teamDetails?teamId=${teamId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getTeamsByUserId(userId: any) {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/teams/${userId}`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getAllRegions(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/regions`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getAllPersonas(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/personas`,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getAllNotifications(): string {
    return this.createUrl(`all`, true);
  }

  public getManageSettings(): string {
    return '';
  }

  public getSaveManageSettings(): string {
    return '';
  }

  public getFRErrors(): string {
    return '';
  }

  public getFRErrorDescritpion(fundRequestId: string) {
    return '';
  }

  public getFRMappingList(frId:any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/fund-request-mappings/${frId}`
    );
  }

  public updateFRMappingList(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/mapping/update`
    );
  }

  public getFRProcessList(): string {
    return '';
  }

  /**
   * getAggregationData
   */
  public getAggregationData(): string {
    return ``;
  }

  public changeAggregationData(): string {
    return ``;
  }

  public getFRRecipientsList(fundRequestId: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/recipients?fundRequestId=${fundRequestId}`
    );
  }

  public getChildrenUrl(fundRequestId: string): string {
    const frIdLowerCase = fundRequestId?.toLowerCase(); //lower case added to fix bug -- data not getting fetched from monodb when id is uppercase
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/auditor-dashboard/children/funds/${frIdLowerCase}`
    );
  }

  public getInputFileCount(fundRequestId:string){
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/auditor-dashboard/fund-information/input-file-status?fundRequestId=${fundRequestId}`
    )
  }

  public getDTChildrenFunds(fundRequestId: string): string {
    const frIdLowerCase = fundRequestId?.toLowerCase(); //lower case added to fix bug -- data not getting fetched from monodb when id is uppercase
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/auditor-dashboard/children/funds/${frIdLowerCase}`
    );
  }

  /**
   * changeDataActionStatus
   */
  public changeDataActionStatus(dataAction: any, fundRequestIds: any[] = []) {
    return this.createUrl(
      `${
        AppConst.VERSION_1
      }/fundrequest/update-data-action?dataAction=${dataAction}&fundRequestIds=${fundRequestIds.join(
        '&fundRequestIds='
      )}`
    );
  }

  public addFRRecipient(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/recipient/add`);
  }

  public deleteFRRecipient(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/recipient/delete`);
  }

  public updateFRRecipientDetails(): string {
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/recipient/update`);
  }

  public getTrailsList(fundRequestId: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/trails?fundRequestId=${fundRequestId}`
    );
  }

  public getIdentifications(fundRequestId: string): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/identificationFiles?fundRequestId=${fundRequestId}`
    );
  }

  public getAuditorDashboardWorkpaperSharepoint(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/dashboard/fileUploadDetails`,
      false,
      false,
      false,
      true,
      false,
      false,
      false
    );
  }

  public getStandardization(fundRequestId: any): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/fundrequest/dt-dashboard/cdm-details?fundRequestId=${fundRequestId}`
    );
  }

  public downloadCDMFile(fundRequestId: string, cdmId: any): string {
        return this.createUrl(
      `${AppConst.VERSION_1}/download/workpaper?fundRequestId=${fundRequestId}&workpaperId=${cdmId}`,
      false,
      false,
      false,
      true
    );
  }

  public getLoginDetails(): string {
    return this.createUrl(
      `${AppConst.VERSION_1}/authentication/getDetails`,
      false,
      false,
      false,
      false,
      false,
      true
    );
  }

  public getSearchUsersFromGraphAPI(searchString: any): string {
    return `https://graph.microsoft.com/v1.0/users?$count=true&ConsistencyLevel=eventual&$filter=startswith(mail,'${searchString}') and endswith(mail,'ey.com')&$select=givenName,surname,mail`;
  }

  public getModuleLevelPermission() {
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/accessDetails`,
      false,
      false,
      false,
      false,
      true
    );
  }

  //selfOnboarding
  public downloadFileFromSharepoint(fileName: string) {
    return this.createUrl(
      `${AppConst.VERSION_1}/sharepoint/download?fileName=${fileName}`,
      false,
      false,
      false,
      true
    );
  }

  public getDownloadOptionsUrl(fundRequestIds:string,downloadOpts:string,dashboard:string){
    //return 'https://wamapp-fa3-dev01.sbp.eyclienthub.com/wamapps/fundrequest/api/v1/fundrequest/download-options?fundRequestId=6709187A-7F9A-4B84-AFA2-8A8E4FEE0B7C&downloadOptions=FUND_REQUEST%2CCALCULATIONS'
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/download-options?dashboard=${dashboard}&fundRequestId=${fundRequestIds}&downloadOptions=${downloadOpts}`
    )
  }
  public getDeletePendingFrUrl(){
    //return 'https://wamapp-fa3-dev01.sbp.eyclienthub.com/wamapps/fundrequest/api/v1/fundrequest/delete-pending-fr'
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/delete-pending-fr`)
  }
  public getRegionConfigURL(){
    //return 'http://localhost:8080/fundrequest/api/v1/fundrequest/date-formats'
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/date-formats`);
  }
  public updateRegionConfigURL(){
    //https://wamapps-fa3-dev02.sbp.eyclienthub.com/fundrequest/api/v1/fundrequest/date-formats/update
    return this.createUrl(`${AppConst.VERSION_1}/fundrequest/date-formats/update`);
  }

  public getUserDetailsUrl(){
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/currentUserDetails`,
      false,
      false,
      false,
      false,
      true
    );
  }
  public getUserDetailsWithPersona(){
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/userDetailsWithPersona`,
      false,
      false,
      false,
      false,
      true
    );
  }
  public getUserTemplateURL(){
    return this.createUrl(
      `${AppConst.VERSION_1}/authorization/user-template-download`,
      false,
      false,
      false,
      false,
      true
    );
  }
}
