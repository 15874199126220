import { Component, OnInit } from '@angular/core';
import { AppConst } from '@app/App.const';
import { ApiEndpointsService } from '@app/shared/common/services/api-endpoint.service';
import { ApiHttpService } from '@app/shared/common/services/api-http.service';
import { CommonMethodsService } from '@app/shared/common/services/common-methods.service';
import { ToasterService } from '@app/shared/common/services/toaster.service';
import { fileDownload } from '@app/shared/common/utils/app-utils';

enum _downloadFileOptions {
  downloadSampleFile = 1,
  downloadDeleveryMemo = 2,
}
@Component({
  selector: 'app-self-onboarding',
  templateUrl: './self-onboarding.component.html',
  styleUrls: ['./self-onboarding.component.scss'],
})
export class SelfOnboardingComponent implements OnInit {
  public tpaName = '';
  public tpaList: any[] = [];
  public selectedTpa = '';
  public isTpaSubmitted = false;

  constructor(
    public Api: ApiHttpService,
    private ApiEndPoint: ApiEndpointsService,
    public toasterService: ToasterService,
    public commonMethods: CommonMethodsService
  ) {}

  ngOnInit(): void {
    this.getTPAs();
  }

  public getTPAs() {
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.ApiEndPoint.getTPAList(),
      null
    ).subscribe((res: any) => {
      if (res?.length) {
        this.tpaList = res;
      }
    });
  }

  get getStatus() {
    return !this.isTpaSubmitted ? 'active' : 'completed';
  }

  get getError() {
    return !!this.tpaList?.find(
      (tpa) => tpa.tpaName?.toLowerCase() === this.tpaName?.toLowerCase()
    );
  }

  get getTpaList() {
    return this.tpaList?.map((tpa) => tpa.tpaName);
  }

  public submitTpa() {
    if (this.tpaName) {
      this.Api.callApi(
        AppConst.HttpMethods.POST,
        this.ApiEndPoint.addNewTpa(),
        {
          tpaName: this.tpaName,
        }
      ).subscribe((res: any) => {
        if (res?.success) {
          this.toasterService.showToasterSuccess(res.message ? res.message : 'TPA uploaded successfully!');
          if (res?.data?.tpaId) {
            this.selectedTpa = res.data.tpaId;
          }
          this.getTPAs();
          this.tpaName = '';
          this.isTpaSubmitted = true;
        } else {
          this.toasterService.showToasterError(res.message ? res.message : 'Failed to upload TPA!');
        }
      });
    } else if (this.selectedTpa) {
      this.isTpaSubmitted = true;
    }
  }

  public openEYDFPortal() {
    this.commonMethods.openUrlInNewTab(AppConst.EYDF_PORTAL_URL);
  }

  public downloadFiles(option: number) {
    let fileName = '';
    if (option === this.downloadFileOptions.downloadSampleFile) {
      fileName = AppConst.sampleFileName;
    } else if (option === this.downloadFileOptions.downloadDeleveryMemo) {
      fileName = AppConst.deleveryMemoFileName;
    }
    this.Api.callApi(
      AppConst.HttpMethods.GET,
      this.ApiEndPoint.downloadFileFromSharepoint(fileName),
      null,
      { responseType: 'blob' as 'json', observe: 'response' }
    ).subscribe((data: any) => {
      if (data?.body && data.status == '200') {
        let blob = new Blob([data.body], { type: data?.body.type });
        let fileName = data.headers.get('filename')?.split('"')[1]
          ? data.headers.get('filename').split('"')[1]
          : data.headers?.get('content-disposition')?.split('"')[1]
          ? data.headers?.get('content-disposition')?.split('"')[1]
          : 'file';
        fileDownload(fileName, blob);
        this.toasterService.showToasterSuccess('File downloaded successfully!');
      } else {
        this.toasterService.showToasterError('Failed to download file!');
      }
    });
  }

  public get downloadFileOptions() {
    return _downloadFileOptions;
  }
}
