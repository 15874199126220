import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDateAngularComp } from 'ag-grid-angular';
import { IDateParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import flatpickr from 'flatpickr';
import { getDateFormat } from '../../utils/app-utils';

@Component({
  selector: 'app-custom-date',
  templateUrl: './custom-date.component.html',
  styleUrls: ['./custom-date.component.scss']
})
export class CustomDateComponent implements IDateAngularComp {
  @ViewChild("flatpickrEl", { read: ElementRef }) flatpickrEl!: ElementRef;
  @ViewChild("eInput", { read: ElementRef }) eInput!: ElementRef;

  private convertToFlatpickrFormat(){
    return this.dateFormat.replace('yyyy','Y').replace('mm','m').replace('dd','d')
  }
  private date!: Date;
  private params!: IDateParams;
  private picker: any;
  public dateFormat=getDateFormat().toLowerCase();

  agInit(params: IDateParams): void {
      this.params = params;
  }

  ngAfterViewInit(): void {
      this.picker = flatpickr(this.flatpickrEl.nativeElement, {
          onChange: this.onDateChanged.bind(this),
          dateFormat: this.convertToFlatpickrFormat(),
          wrap: true
      });

      this.picker.calendarContainer.classList.add('ag-custom-component-popup');
  }

  onDateChanged(selectedDates: any) {
      this.date = selectedDates[0] || null;
      this.params.onDateChanged();
  }

  getDate(): Date {
      return this.date;
  }

  setDate(date: Date): void {
      this.date = date || null;
      this.picker.setDate(date);
  }

  setInputPlaceholder(placeholder: string): void {
      this.eInput.nativeElement.setAttribute('placeholder', this.dateFormat);
  }

  setInputAriaLabel(label: string): void {
      this.eInput.nativeElement.setAttribute('aria-label', label);
  }

}
