import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { SettingsService } from '@app/shared/common/services/Authentication/settings.service';
import { WebSocketConfigService } from '@app/shared/common/services/Authentication/web-socket-config.service';
import { ConfigurationsService } from '@app/shared/common/services/Configuration/configurations.service';
import { CommonMethodsService } from '@app/shared/common/services/common-methods.service';
import { LoaderService } from '@app/shared/common/services/loader.service';
import { SignalRConnectionService } from '@app/shared/common/services/signal-r-connection.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  public showHeaderFooter: boolean = true;
  public showLoader: boolean = false;
  public isPermissionsLoaded: boolean = false;
  constructor(
    public loaderService: LoaderService,
    private settinService: SettingsService,
    private router: Router,
    private webSocketService: WebSocketConfigService,
    private SignalRConnectionService: SignalRConnectionService,
    private configService: ConfigurationsService,
    private commonService: CommonMethodsService
  ) {
    // To hide header and footer from login page
    this.router.events.subscribe((event: any) => {
      if (event instanceof RouteConfigLoadStart) {
        this.showLoader = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.showLoader = false;
      }
      if (event instanceof NavigationStart) {
        //for future reference
      }
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = this.settinService.isUserLoggedin();
        if (this.showHeaderFooter && !this.isPermissionsLoaded) {
          this.isPermissionsLoaded = true;
          this.settinService.getPermissionsByModule();
          this.commonService.getUserDetails();
          this.WebsocketConnect();
          this.configService.getRegionConfig()
          // this.SignalRConnectionService.getAllNotifications();
        }
        // closing any tooltip remained open in previous page
        this.closeAllTooltips()
      }
    });
  }
  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((data: any) => {
      this.showLoader = data;
    });
  }
  public closeAllTooltips() {
    const closeArray=document.querySelectorAll(
      '#custom-teams-operation-tooltip  .motif-tooltip-close-button'
    );
    closeArray.forEach((ele: any) => ele.click())

  }

  WebsocketConnect() {
    let stompClient = this.webSocketService.connect();
    console.log(stompClient);
    console.log(stompClient?.connected);
    // stompClient.subscribe((res: any) => {
    //   console.log(res);
    // });
    stompClient.connect({}, (frame: any) => {
      console.log(frame);
      console.log(stompClient?.connected);
      // Subscribe to notification topic
      stompClient.subscribe('api/topic/signoff', (notifications: any) => {
        // Update notifications attribute with the recent messsage sent from the server
        console.log(notifications);
      });
      stompClient.subscribe('api/topic/signOff', (notifications: any) => {
        // Update notifications attribute with the recent messsage sent from the server
        console.log(notifications);
      });
      stompClient.subscribe('api/topic/topic/signOff', (notifications: any) => {
        // Update notifications attribute with the recent messsage sent from the server
        console.log(notifications);
      });
    });
  }
}
