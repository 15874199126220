<!-- back button -->
<div class="motif-row" *ngIf="isNotificationScreen">
  <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
    <div class="back-arrow" (click)="navigateBack()">
      <motif-icon
        [src]="'/assets/icons/hardware/ic_keyboard_backspace_24px.svg'"
      ></motif-icon
      >&nbsp;&nbsp; | Back
    </div>
  </div>
</div>

<!-- title with count -->
<div class="motif-row dummy-row-1rem" *ngIf="isNotificationScreen">
  <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
    <p class="page-title">
      <strong>{{ "Manage Notifications" }}</strong>
    </p>
    <span class="motif-subtitle"
      >Control what notification to display by enable or disable switch.</span
    >
  </div>
</div>

<!-- wrap in white background -->
<div class="wrap-in-white dummy-row-1rem" *ngIf="isNotificationScreen">
  <div class="motif-row">
    <div class="motif-col-md-8 motif-col-sm-4 motif-col-xs-4">
      <div class="button-container">
        <div
          class="motif-col-md-2 motif-col-sm-2 motif-col-xs-2 btn-container-left"
        >
          <button
            motifButton
            color="secondary"
            [disabled]="!modifiedFlag"
            (click)="saveManageNotifications()"
          >
            Save
          </button>
          <button
            motifButton
            color="secondary"
            [disabled]="!modifiedFlag"
            (click)="getManageSettings()"
          >
            <motif-icon
              [src]="'/assets/icons/navigation/ic_refresh_24px.svg'"
            ></motif-icon
            >Reset
          </button>
        </div>
        <div
          class="motif-col-md-6 motif-col-sm-2 motif-col-xs-2 btn-container-right"
        >
          <!-- search operation -->
          <div class="btn-container-item search-field">
            <app-search
              [isForAgGridTable]="false"
              (searchTextEmit)="searchText = $event"
            ></app-search>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- wrap in white background -->
<form #f="ngForm">
  <div
    class="motif-row wrap-in-white"
    *ngFor="
      let setting of manageNotificationSettings | searchFilter : searchText;
      let index = index
    "
  >
    <div
      class="motif-col-md-6 motif-col-sm-3 motif-col-xs-3 manage-setting-left"
    >
      <div class="manage-setting-title">
        <strong>{{ setting.title }}</strong>
      </div>
      <div class="manage-setting-sub-title">{{ setting.description }}</div>
    </div>
    <div
      class="motif-col-md-2 motif-col-sm-1 motif-col-xs-1 manage-setting-right"
    >
      <motif-form-field *ngIf="!isNotificationScreen">
        <motif-checkbox>Mandatory</motif-checkbox>
      </motif-form-field>
      <motif-toggle-switch
        [disabled]="setting.isMandatory"
        [(ngModel)]="setting.isEnabled"
        [prelabel]="'Disable'"
        [postlabel]="'Enable'"
        id="enableDisableForm{{ index }}"
        name="enableDisable{{ index }}"
        #enableDisable{{index}}="ngModel"
        (change)="modifiedFlag = true"
      ></motif-toggle-switch>
    </div>
  </div>
  <div
    class="wrap-in-white"
    *ngIf="
      (manageNotificationSettings | searchFilter : searchText).length === 0
    "
  >
    {{ "No settings found!" }}
  </div>
</form>
