import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-cell-renderer',
  templateUrl: './cell-renderer.component.html',
  styleUrls: ['./cell-renderer.component.scss'],
})
export class CellRendererComponent implements ICellRendererAngularComp {
  template!: TemplateRef<any>;
  parentStyle!: any;
  templateContext!: { $implicit: any; params: ICellRendererParams };
  isCompleteDataReq: boolean = false;
  public colDefFor: string = '';

  agInit(params: any): void {
    this.template = params['ngTemplate'];
    this.parentStyle = params && params['style'];
    this.isCompleteDataReq = params?.isCompleteDataReq ? true : false;
    this.colDefFor = params?.colDefFor ? params.colDefFor : '';
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    this.templateContext = {
      $implicit: this.isCompleteDataReq ? params : params.data,
      params,
    };
    return true;
  }
}
