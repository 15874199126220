import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Input() user:any={}
  @Input() public logout = ()=>{};
  @Output() closeModel = new EventEmitter<any>();
  
  constructor() { }
  getFirstName(){
    return this?.user?.name && this.user.name.split(' ')[0];
  }
  getLastName(){
    return this?.user?.name && this.user.name.split(' ').at(-1);
  }
  public handleCloseClick(){
    this.closeModel.emit(false);
  }
  ngOnInit(): void {
  }

}
