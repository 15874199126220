enum groupTypes {
  fundRequest,
  workpaper,
  dataIngestion,
  error,
}

//maintain unique numbers for both the enumbs as isDisable method used for both is same --starts
enum workpaperActionsEnum {
  Rerun = 1,
  ReleaseAll = 2,
  UnRealeaseAll = 3,
}

enum moreActionsEnum {
  enableExternalValuation = 100,
  disableExternalValuation = 101,
  reset = 102,
  delete = 103,
}
//maintain unique numbers for both the enumbs as isDisable method used for both is same --ends

enum DownloadOptionsEnum {
  workpaper,
  fundRequest,
  calculation,
  inputFiles,
  cdm
}

export {
  groupTypes,
  workpaperActionsEnum,
  moreActionsEnum,
  DownloadOptionsEnum,
};
